

.TabA{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 2px;
  z-Index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
background: transparent;

}

.img-A{
  width: 850px;
  height: 640px;
}

.close-A{
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}
.text-table-columnA{
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}
.text-image{
  margin-top: -6%;
  text-align: center;
  font-family: 'Merriweather', serif;
  font-size: 13px;
  color: var(--deep-dark);
  font-weight: 600;
}
.text-tableA{
  text-align: center;
  font-family: 'Merriweather', serif;
  font-size: 19px;
  font-weight: bolder;
  color: var(--deep-dark);
}
  .tableA{
    border: 2px solid #032144;
    padding: 10px;
    margin-right: 40px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }

  @media all and (max-width:486px) {


    .img-A{
      width: 450px;
      height: 340px;
    }
      }