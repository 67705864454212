/*levels*/

.coming-newDesign{
    background-color: var(--color);
	padding: 20px;
	margin-right: -8%;
	margin-top: -2%;
	width: 180px;
	color: var(--light-color);
	font-size: 22px;
    text-align: center;
	margin-left: auto;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	text-transform: uppercase;
}

.level-paid {


	
	flex-direction: column;
	justify-content: center;
	border-radius: 8px;
	padding: 30px;
	background-image: url(../../public/images/level.jpg);
	transition: transform .1s;
		background-position-x: center;
	background-size: cover;
	width: 455px;
	max-height: 20000px;
	cursor: pointer;
	box-shadow: 0px 0px 8px 6px #75e675;
	text-align: start;
	position: relative;
	margin-bottom: 8%;
word-wrap: break-word;


	

}
.level-item{
	flex-direction: column;
	justify-content: center;
	padding: 36px;
	text-align: center;
	width: 460px;
	max-height: 1050px;
		margin-bottom: 10%;
		cursor: pointer;
}
.description-future{
	word-wrap: break-word;
}
.level-paid:hover {
	transform: scale(1.1);
}
.text-description-future{
		max-height: 20850px;
		width: 100%;
		word-wrap: break-word;
		}
	

.btn-levels-paid {

	border: none;
	border: 4px #244B7A solid ;
	background: none;
	border-radius: 10px;
	width: 240px;
	height: 50px;
	color: var(--main-color);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
	left: 50%;
	transform: translate(-50%, 0);
	bottom: 30px;
	margin-bottom: -4%;
	position: absolute;
}

.text-discription-future {

	width: 100%;
	margin-bottom: 1%;
	word-wrap: break-word;
	}


.center-level{
	display: flex;
	flex-direction: column;
	justify-content: center;
margin-top: 12%;
display: block;
}

.btn-levels-paid:hover {
	background: var(--main-color);
	color: var(--light-color);
}
.appliName-levelOne-free{
	margin-top: 10px;
	text-align: center;
	font-size: 35px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;
	position: relative;
}





/*button*/

.welcome {
    background-color: transparent;
    color: var(--main-color);
	font-size: 20px;
	font-weight: bold;
    -webkit-animation: pulsing 2s infinite;
    animation: pulsing 2s infinite;

	text-align: start;

  }

  .about-us-main{
	width: 150px;
  }
  .sign-l{
	margin-left: -3%;
  }
  .wel-email{
  
	  font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 24px;
		text-decoration: none;
		color: var(--color);
		padding: 10px 14px;
  text-align: center;
	  cursor: pointer;
		transition: transform .5s;
	}


  
@-webkit-keyframes pulsing {
    0% {
      -webkit-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6)
    }
    50% {
      -webkit-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7);
    }
    100% {
      -webkit-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6);
    }
  }
  
  @keyframes pulsing {
    0% {
      -webkit-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6)
    }
    50% {
      -webkit-transform: scale(0.7, 0.7);
      transform: scale(0.7, 0.7);
    }
    100% {
      -webkit-transform: scale(0.6, 0.6);
      transform: scale(0.6, 0.6);
    }
  }

  @media (max-width:636px){
	.level-paid:hover{
		transform: none;
	}
	
  }
  @media (max-width:456px){
	.level-paid{
		width: 445px;
	  }
	}
  @media (max-width:446px){
	.level-paid{
		width: 435px;
	  }
	}
  @media (max-width:436px){
	.level-paid{
		width: 420px;
	  }
	}
  @media (max-width:426px){
	.level-paid{
		width: 410px;
	  }
	}
  @media (max-width:416px){
	.level-paid{
		width: 400px;
	  }
	}
  @media (max-width:406px){
	.level-paid{
		width: 395px;
	  }
	}
  @media (max-width:396px){
	.level-paid{
		width: 375px;
	  }
	  .coming-newDesign{
		margin-right: -15%;
	  }
  }
  @media (max-width:386px){
	.level-paid{
		width: 375px;
	  }
  }
  @media (max-width:376px){
	.level-paid{
		width: 360px;
	  }
  }
  @media (max-width:366px){
	.level-paid{
		width: 355px;
	  }
  }
  @media (max-width:356px){
	.level-paid{
		width: 345px;
	  }
  }
  @media (max-width:346px){
	.level-paid{
		width: 335px;
	  }
  }
  @media (max-width:336px){
	.level-paid{
		width: 325px;
	  }
  }
  @media (max-width:326px){
	.level-paid{
		width: 310px;
	  }
  }
  @media (max-width:316px){
	.level-paid{
		width: 310px;
	  }
  }
  @media (max-width:306px){
	.level-paid{
		width: 300px;
	  }
  }
  @media (max-width:296px){
	.level-paid{
		width: 280px;
	  }
  }
  @media (max-width:286px){
	.appliName-levelOne-free{
		font-size: 24px;
	  }
	  .level-paid{
		width: 270px;
	  }
	  .description-future{
		margin-left: -1.8%;
		word-wrap: break-word;
	}
	  .coming-newDesign{
		margin-right: -15%;
	  }
  }
