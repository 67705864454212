/* 
.call-form-container {
    position: fixed;
    z-index: 1000;
    bottom: 20px;
    left: 20px;
  }
  
  .expanded-form {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    left: 20px;
    max-width: 280px;
    height: 350px;
margin-right: auto;
margin-left: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  }
  

  .close-form {
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }
  
.h2 {
  text-align: center;
  margin-bottom: 20px;
}

.p{
  text-align: center;
  margin-bottom: 20px;
}
.h4 {
text-align: center;
  margin-bottom: 20px;
}


.input-name{
  padding: 8px;
  align-self: center;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 200px;


}

.input-phone {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #ccc;
display: flex;
justify-content: center;
  width: 200px;

}

 .button-phone {
  padding: 10px;
  background-color: var(--main-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  text-align: center;
}

.button-phone:hover {
  background-color: var(--dark-color);
}
  

@media (max-width:285px){

  .call-form-container {
    position: fixed;
    bottom: auto;
    right: auto;

  }
  
} */


.call-form-container {
  position: fixed;
  z-index: 1000;
  bottom: 70px;
  left: 20px;
}

.expanded-form {
  position: fixed;
  z-index: 1000;
  bottom: 40px;
  left: 20px;
  max-width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.expanded-form h2 {
  text-align: center;
  margin-bottom: 20px;
  max-width: 280px;
}

.close-form {
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: bold;
  color: black;
  cursor: pointer;
}

.h2 {
text-align: center;
margin-bottom: 20px;
}

.p{
text-align: center;
margin-bottom: 20px;
}

.form-group {
flex-direction: column;
margin-bottom: 15px;
max-width: 280px;
}

.label-phone {
display: block;
margin-bottom: 5px;
}

.input-phone {
width: 100%;
padding: 8px;
font-size: 16px;
border-radius: 3px;
border: 1px solid #ccc;
margin-left: 0px;
}

.input-ph{
  width: 100%;
}

.button-phone {
width: 100%;
padding: 10px;
background-color: #007bff;
color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
font-size: 16px;
transition: background-color 0.3s;
}

.button-phone:hover {
background-color: #0056b3;
}


.form-toggle {
  display: block;
  padding: 15px;
  background-color: var(--dark-color);
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
  animation: pulse 1s infinite;


}



@keyframes pulse {
  0% { 
    transform: scale(0.8);
  }

  50% { transform: scale(1.1);
  }

  100% {
    transform: scale(0.8);
  } 
}

.form-toggle:hover {
  background-color: var(--main-color);
}
@media (max-width:806px){
  .input-phone{
    max-width: 220px;
  }

  .button-phone{
    max-width: 220px;
  }

  .input-ph{
    max-width: 220px;
  }
}

@media (max-width:286px){
  .input-phone{
    max-width: 220px;
  }

  .button-phone{
    max-width: 220px;
  }

  .input-ph{
    max-width: 220px;
  }
  .expanded-form {
margin-left: -5%;
width: 275px;
    }
  
}