
.popup-content-new-t{
  width: 1050px;
    }
    .popup-new-t {
      position: fixed;
      display: flex;
      justify-content: center;
          width: 100%;
          height: 850px;
           /* background-image: url("..//..//..//public/images/sale.png");  */
      text-align: center;
          z-index: 2;
          margin-top: 5%;
      
        }
  .popup-close-buttonn{
      position: absolute;
  width: 34px;
  height: 30px;
      font-weight: bold;
      color: var(--deep-dark);
      font-weight: bolder;
      cursor: pointer;
      font-size: 18px;
      background-color: var(--light-color);
      border: none;
      box-shadow: 0px 0px 1px 1px #8f8e8e;
  }
    .sale-bannerr{
        background-position: center;
      width:1050px;
  height:480px;
          background-size: cover;
          background-repeat: no-repeat;
          box-shadow: 0px 0px 8px 4px #faf9f9;
    }
  
    @media all and (max-width:1286px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 1180px;
        height: 490px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 10%;
  
      }
      .popup-close-buttonn{
        font-size: 18px;
        width: 32px;
        height: 30px;
      }
    }
    @media all and (max-width:1256px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 1160px;
        height: 480px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 10%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:1156px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 1000px;
        height: 440px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 15%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:1086px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 960px;
        height: 440px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 15%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:1026px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 900px;
        height: 400px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 15%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:986px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 860px;
        height: 400px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 15%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:906px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 800px;
        height: 360px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 15%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:826px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 700px;
        height: 340px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 20%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:786px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 660px;
        height: 300px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 25%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
    @media all and (max-width:726px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 600px;
        height: 300px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 25%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 26px;
        height: 24px;
      }
    }
  
    @media all and (max-width:686px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 565px;
        height: 280px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 25%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 20px;
        height: 18px;
      }
    }
    @media all and (max-width:626px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 540px;
        height: 260px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 25%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 20px;
        height: 18px;
      }
    }
  
    @media all and (max-width:586px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 500px;
        height: 260px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 35%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 20px;
        height: 18px;
      }
    }
  
    
    @media all and (max-width:536px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 480px;
        height: 260px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 35%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 20px;
        height: 18px;
      }
    }
    @media all and (max-width:526px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 420px;
        height: 200px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 35%;
  
      }
      .popup-close-buttonn{
        font-size: 14px;
        width: 20px;
        height: 18px;
      }
    }
    @media all and (max-width:486px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 380px;
        height: 180px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }
    @media all and (max-width:426px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 340px;
        height: 160px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }
    @media all and (max-width:386px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 300px;
        height: 150px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }
    @media all and (max-width:316px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 260px;
        height: 140px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }
    @media all and (max-width:306px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 250px;
        height: 140px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }
    @media all and (max-width:286px) {
  
      .popup-content-new-t{
        width: 100%;
      }
  
      .sale-bannerr{
        width: 240px;
        height: 130px;
        box-shadow: 0px 0px 2px 2px #faf9f9;
      }
  
      .popup-new-t{
        margin-top: 45%;
  
      }
      .popup-close-buttonn{
        font-size: 12px;
        width: 18px;
        height: 16px;
      }
    }