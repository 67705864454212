.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
}

.modal-content p {
  margin-bottom: 10px;
}

.modal-content label {
  display: block;
  margin-bottom: 10px;
}

.modal-content button {
  padding: 10px 15px;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto; /* Размещаем кнопку по центру блока */
}

.modal-content button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.disclaimer-text {
  border: 2px solid red; /* Добавляем красную рамку для внутреннего текста */
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box; /* Учитываем ширину рамки внутри блока */
  margin-bottom: 10px;
}

.agreement-text{
  border: 2px solid red; /* Добавляем красную рамку для внутреннего текста */
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box; /* Учитываем ширину рамки внутри блока */
  margin-bottom: 10px;
  overflow-y: scroll;
  max-height: 350px;
}

.agreement{
  max-width: 700px;
}


.popup-container {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный фон */
  
}

.popup {
  background-image: url();
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Тень */
  max-width: 460px;
  text-align: center;
  background-image: url(../public/images/2.jpg);
  background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.button-popup {
  padding: 10px 20px;
  background-color: #244B7A; /* Цвет кнопки */
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.button-popup:hover {
  background-color: #032144; /* Цвет кнопки при наведении */
}

.p-popup {
  font-size: 16px;
  line-height: 1.6;
  color: #333; /* Цвет текста */
  border: 2px solid #244B7A; /* Синяя граница */
  font-family: 'Merriweather', serif;
  padding: 16px;
font-weight: bolder;
border-radius: 8px;
}
