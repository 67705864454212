body,
html {
	margin: 0;
}



@font-face {
	font-family: 'Merriweather';
	src: url(../Anketa/Merriweather-Light.ttf);
}

* {
	box-sizing: border-box;
   }


.employer-first-block {
	width: 100%;
	background-image: url(../../../public/images/firstPhoto.jpg);
	background-position: center;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}
.emp-form{
	display: flex;
	justify-content: space-evenly;
}
.first-block-employer {
	display: flex;
	flex-direction: column;
	max-width: 880px;
	justify-content: center;
	padding: 120px;
}
.link-emp-form{
	display: flex;
	flex-direction: column;
	align-self: center;
}

.block-link{
	background-color: rgba(255,255,255,0.5);
padding: 60px;
}
.text-form-click{
	font-size: 16px;
	font-weight: 600;
	color: var(--dark-color);
	font-family: 'Merriweather', serif;
	display: flex;
	justify-content: center;
	max-width: 360px;
	text-align: center;
}
.link-emp{
	display: flex;
	justify-content: center;
	text-transform: uppercase;
	border-radius: 10px;
	border: none;
	width: 240px;
	height: 50px;
	font-size: 17px;
	color: #ffffff;
	padding: 13px 60px;
	background-color: var(--main-color);
	margin: auto;
	outline: none;
	cursor: pointer;
	transition: all 0.1s;
	text-align: center;
	animation-name: color;
	animation-duration: 2s;
	animation-iteration-count:infinite;

}

#formEmp{
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes color {
	from {background-color: var(--main-color);}
	to {background-color: var(--color);}
  }

  /* .animation{
	transition: background-color 0.3s;
	animation: pulse 3s infinite;
}
@keyframes pulse {
	0% { 
	  transform: scale(0.8);
	}
  
	50% { transform: scale(1.1);
	}
  
	100% {
	  transform: scale(0.8);
	} 
  } */
.h2-employer,
.h2-employer-light {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 50px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
}

.text-employer,
.text-employer-light {
	font-size: 20px;
	font-weight: 600;
	color: var(--dark-color);
	font-family: 'Merriweather', serif;
	text-align: justify;
}


.hr-emp {
	width: 100%;
	height: 12px;
	background-color: var(--main-color);
}


.employer-benefits {
	width: 100%;
	display: flex;
}


.photo-block-employer {
	display: flex;
	justify-content: center;
	background-color: var(--dark-color);
	width: 100%;
}

.photo-ben {
	align-self: center;
	box-shadow: 0px 0px 8px 2px #b5b3b3;
}


.block-benefits-employer {
	display: flex;
	flex-direction: column;
	max-width: 900px;
	justify-content: center;
	padding: 60px;
}

.par-benefits-employer {
	font-size: 18px;
	font-weight: 600;
	color: var(--dark-color);
	font-family: 'Merriweather', serif;
	padding: 10px;
	text-align: justify;
}

.par-employer-ben {
	font-size: 22px;
	font-weight: 800;
	color: var(--dark-color);
	text-align: center;
	text-transform: uppercase;
	margin-bottom: -4%;
}


.documents {

	background-image: url(../../../public/images/documents.jpg);
	background-position: center;
	height: 100%;
	width: 100%;
	background-size: cover;
	background-repeat: no-repeat;
}

.doc-table {
	display: flex;
	flex-direction: column;
	justify-content: center;

}
.perm{
	width: 100%;
	margin-bottom: 10%;
}

.h2-employer-table {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 50px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
	margin-top: 7%;
	margin-bottom: 4%;
}
.cost-table{
	width: 100%;
}
.cost-emp{
list-style-type: none;

}
.perm {
	background-color: var(--light-color);
	font-size: 20px;
	font-weight: bold;
}

.table-color {
	background-color: var(--light-color);
	color: var(--deep-dark);
	font-size: 18px;
	font-weight: 800;
}

.table-time {
	width: 100%;

	height: 100%;
	margin-top: -2%;
}

.h2-table {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 50px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
	margin-top: 7%;
}

.table-time {
	padding-top: 40px;
	padding-bottom: 40px;
	align-self: center;
}

.text-table-time {
	font-size: 16px;
	font-weight: lighter;
	color: var(--dark-color);
	font-family: 'Merriweather', serif;
	word-spacing: 11px;
	max-height: 450px;
	text-align: center;
}

.time {
	width: 100%;
}

.summary {
	width: 100%;
	background-image: url(../../../public/images/summary.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.summary-form {
	display: flex;
	justify-content: space-evenly;
}
.comment-form {
    width: 100%;
}
.block-summary {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	max-width: 850px;
	padding: 60px;
}

.text-summary {
	font-size: 20px;
	font-weight: 600;
	color: var(--dark-color);
	font-family: 'Merriweather', serif;

	align-self: center;
	text-align: justify;
}


.app-wrapper-main-sum{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 100% ;
	border: 3px #244B7A solid;
	background-color: #ffffff;
	overflow: hidden;
  } 

.app-wrapper-form{
display: flex;
flex-direction: column;
justify-content: center;

	padding: 14px;


  }
  .logoForm-sum{
	margin-bottom: 10px;
	text-align: center;
	}
  .email-log-sum, .password-log-sum{
	margin: 10px;
	color: var(--deep-dark);
	font-size: 19px;
	width: 100%;
  
  }
  .label-log-sum{
  width: 100%;
	font-family: 'Merriweather', serif;
	  font-size: 18px;
	  font-weight: 600;
	  word-spacing: 10px;
  }
  .password-log{
	margin-bottom: 60px;
  
  }
  .start{
	margin-right: auto;
  }
  .input-log{
	height: 32px;
	font-size: 20px;
	outline: none;
  width: 100%;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #d8d8d8;
  }

.PhoneInputInput{
	height: 32px;
	font-size: 20px;
	outline: none;
  width: 100%;
	height: 36px;
	border-radius: 6px;
	border: 1px solid #d8d8d8;
}

.form-wrapper-sum {
	display: flex;
	justify-content: center;

}

.sum-right{
	margin-right: 5%;
}
.sum-form{
	display: flex;
flex-direction: column;
justify-content: center;
}


.title-form {
	color: var(--dark-color);
	text-align: center;
	font-size: 28px;
	margin: 10px 0px 40px 0px;
}


.react-tel-input .form-control {
	width: 100%;
	outline: none;
	margin-right: 5%;
}


.comment-form {
height: 80px;
	width: 100%;
	margin-right: 5%;
}

.error {
	color: var(--color);
	font-weight: 600;
	margin-left: 30px;
	margin-top: -1px;
}
.error-phone-left{
margin-left: 22%;
font-size: 18px;
color: var(--color);
font-weight: 600;
margin-top: -1px;
}
.error-left {
	color: var(--color);
	margin-left: 20px;
	margin-top: -1px;
	font-weight: 600;

}
.btn-submit{
	display: flex;
	justify-content: center;
}
.submit-send {
	text-transform: uppercase;
	border-radius: 10px;
	border: none;
	width: 240px;
	height: 50px;
	font-size: 18px;
	color: #ffffff;
	padding: 14px 60px;
	background-color: var(--main-color);
	outline: none;
	cursor: pointer;
	transition: all 0.1s;
	margin-top: 10%;
}
 .but-sum{
margin-bottom: 55%;
 }
 .submit-send:hover {
	background: var(--dark-color);
	color: var(--light-color);
}

@media all and (max-width:1280px) {


	.left-phone {
		margin-left: 7%;
	}

}

@media all and (max-width:1024px) {
	.photo-block-employer {
		display: none;
	}

	.header-nav-item {
		font-size: 16px;
	}

	.summary-form {
		display: flex;
		flex-direction: column;
	}

	.block-summary {
		padding: 40px;
	}


}
@media all and (max-width:926px){
.emp-form{
	display: flex;
	flex-direction: column;
}
.block-link{
	margin-bottom: 10%;
	padding: 40px;
}
}
@media all and (max-width:920px) {
	.photo-block-employer {
		display: none;
	}

	.h2-employer,
	.h2-employer-light,
	.h2-employer-table,
	.h2-table {
		margin-top: 30px;
		margin-bottom: 10px;
		font-size: 40px;
		font-weight: bolder;
	}

	.summary-form {
		display: flex;
		flex-direction: column;
	}

	.left-phone {
		margin-left: 22%;
	}

	.cost-emp{
		width: 100%;
	}
}


@media all and (max-width:806px) {
    
    .text-employer
    {

      text-align: start;
	  word-spacing: 2px;
    }
  
    .par-benefits-employer{
      text-align: start;

    }
    .text-summary{
  text-align: start;

    }
  }

@media all and (max-width:800px) {
	.photo-block-employer {
		display: none;
	}

	.h2-employer,
	.h2-employer-light,
	.h2-employer-table {
		margin-top: 30px;
		margin-bottom: 10px;
	}

	.left-phone {
		margin-left: 22%;
	}


}

@media (max-width: 768px) {
	td {
		font-weight: 500;
		word-spacing: 10px;
		padding: 12px 11px;
	}
}

@media (max-width:609px){
	.first-block-employer {
		padding: 30px;
	}
}

@media (max-width:579px) {
	.header-nav {
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
	}

	.header-nav.active {
		transform: translateX(100%);
	}

	.header-nav-list {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-top: 10px;
	}
	.cost-table{
		width: 100%cd;
	}

	.header-nav-item {
		text-align: center;
		align-items: center;
		font-weight: 600;
		font-size: 20px;
		padding: 16px 26px;
	}

	.header-nav-item:nth-child(n + 2) {
		margin-left: 0;
	}

	.header-menu-button {
		display: block;
		margin-left: auto;
		background-color: transparent;
		padding: 0;
		border: 0;
	}

	.youtube-level1 {
		margin-top: 60px;
		width: 350px;
		height: 215px;
	}


	.level-first-block {
		display: flex;
		flex-direction: column;
	}

	.text-employer-benefits {
		font-size: 16px;
		word-spacing: 14px;
		max-height: 850px;
	}

	td {
		font-weight: 500;
		word-spacing: 10px;
		padding: 12px 11px;
	}

	.first-block-employer {
		padding: 30px;
	}
}

@media (max-width:440px) {
	.left-phone {
		margin-left: 12%;
	}
	.first-block-employer {
		padding: 20px;
	}

}

@media (max-width:412px) {
	.left-phone {
		margin-left: 12%;
	}
	.first-block-employer {
		padding: 20px;
	}

}

@media (max-width:400px) {
	.left-phone {
		margin-left: 10%;
	}
	.first-block-employer {
		padding: 20px;
	}

}

@media all and (max-width: 396px){
	.h2-employer, .h2-employer-table, .h2-table{
		font-size: 30px;
	}
}
@media (max-width:380px) {

	.h2-employer,
	.h2-employer-light,
	.h2-employer-table,
	.h2-table {
		margin-top: 20px;
		margin-bottom: 10px;
		font-size: 20px;
		font-weight: 600;
	}


	.first-block-employer {
		padding: 20px;
	}

	.text-employer,
	.text-employer-light {
		font-size: 16px;
		font-weight: 500;
		color: var(--dark-color);
		font-family: 'Merriweather', serif;
		word-spacing: 14px;
	}

	.text-employer-benefits {
		font-size: 16px;
		word-spacing: 14px;
		max-height: 850px;
	}

	table,
	th,
	td {
		border: rgb(5, 2, 13) 1px solid;
		width: 100%;
	}

	td {
		background-color: var(--main-color);
		color: var(--light-color);
		font-family: 'Merriweather', serif;
		font-weight: 500;
		word-spacing: 8px;
		padding: 14px 20px;
	}

	.table {
		padding-top: 30px;
		padding-bottom: 80px;
	}

	.table-color {
		font-size: 16px;
		font-weight: 600;
	}

	td {
		font-weight: 500;
		word-spacing: 10px;
		padding: 12px 11px;
	}

	.text-employer-benefits {
		font-size: 14px;
		font-weight: 500;
	}

	.par-benefits-employer {
		font-size: 16px;
		font-weight: 600;
		padding: 8px;
	}

	.block-benefits-employer {
		padding: 30px;
	}

	.text-table-time {
		font-size: 12px;
		color: rgb(197, 193, 193);
		word-spacing: 11px;
		max-height: 80px;
	}

	.text-summary {
		font-size: 16px;
		font-weight: 500;
		color: var(--deep-dark);
		word-spacing: 11px;
	}

	.title-form {
		font-size: 23px;
	}

	.left-phone {
		margin-left: 7%;
	}
 .app-wrapper-form{
	margin-top: 10%;
 }

}
@media all and (max-width:366px){
.text-employer{
	text-align: start;
	word-spacing: 3px;
}
}
@media all and (max-width:316px){
	.block-link{
		margin-bottom: 10%;
		padding: 20px;
	}
}