body,
html {
	margin: 0;
}

* {
	box-sizing: border-box;
   }


@font-face{
	font-family: 'Merriweather';
	src: url(../Anketa/Merriweather-Light.ttf);
}


* {
	box-sizing: border-box;
}



/*header*/
.header-level{
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100vh;
	padding: 0 20px;
	background-color: transparent;
	box-shadow: 0 0 4px 4px rgba(21, 1, 202, 0.25);
	background: transparent;
	position: relative;

  
  }
  
  .header-logo{
	font-size: 20px;
	line-height:1 ;
	font-weight: 700px;
	color: beige;
	margin-top: 40px;
  }
  
  .header-nav{
	margin-left: auto;

  }
  
  .header-nav-list{
	display: flex;
	list-style-type: none;

  }
  
  .header-nav-items{
	line-height: 1;
	font-family: 'Roboto', sans-serif;
	  font-weight: 700;
	  font-size: 24px;
	  text-decoration: none;
	  color: var(--dark-color);
	  padding: 10px 12px;
	cursor: pointer;
	  transition: transform .5s;

  }
  
  .header-nav-items:hover {
	  color: var(--color);
	  -ms-transform: scale3d(1.1, 1.1, 1.1);
	  -webkit-transform: scale3d(1.1, 1.1, 1.1);
	  transform: scale3d(1.1, 1.1, 1.1);
	  transition: 0.5s ease-in-out;
  }

  .header-nav-item:nth-child(n + 2){
	margin-left: 18px;
  }
  
  .header-menu-button{
	display: none;
  }
  
  
  .login-l, .sign-l {
  margin-bottom: 20px;
  
  }

  .item-buttons {
	  font-family: 'Roboto', sans-serif;
	  font-weight: 100;
	  font-size: 20px;
	  text-decoration: none;
	  color: var(--dark-color);
	  padding: 18px 40px;
	  word-wrap: break-word;
  }
  
  
  .login-l {
	  background-color: var(--main-color);
	  color: var(--light-color);
  
	
  }
  
  .login-l:hover {
	  background-color: var(--color);
	  color: var(--light-color);
	  border: none;
  }
  
  .sign-l {
	  background-color: var(--color);
	  color: var(--light-color);
  
  }
  
  .employer{
	color: var(--color);

  }
  .employer:hover{
	color: var(--dark-color);
  }
  
  .sign-l:hover {
	  background-color: var(--main-color);
	  color: var(--dark-color);
	  border: none;
	  color: var(--light-color);
  }
  
  
  
  

/*main photo*/
.photoMain{
	background-image: url(../../../public/images/mainphoto.jpg);
	background-position: center;
  width: 100%;
  height: 100vh;
	  background-size: cover;
	  background-repeat: no-repeat;
	  overflow: hidden;
}
.first-text, .second-text, .third-text{

	word-wrap: break-word;
}
.first-text, .second-text{
	margin-bottom: -60px;
}
.burgermenu {
	display: none;
	position: absolute;
	top: 20px;
	right: 20px;
	font-size: 20px;
}
.center-text{
	display: flex;
	flex-direction: column;
	justify-content: center;
margin-top: 2%;


}
.fly-text {
	align-self: center;
text-align: center;
	position: absolute;
	top: 20%;
	line-height: 14px;

}

#first-text{
	font-family: 'Roboto', sans-serif;
	font-size: 60px;
	font-weight: 900;
	color: var(--dark-color);
	text-shadow: 1px 1px 4px rgb(222, 220, 220);
text-align: center;

word-wrap: break-word;

}

#second-text{
	font-family: 'Roboto', sans-serif;
	font-size: 45px;
	font-weight: 900;
	color: var(--main-color);
	text-shadow: 1px 1px 4px rgb(255, 253, 253);
text-align: center;
word-wrap: break-word;
}

#third-text {
	font-family: 'Roboto', sans-serif;
	font-size: 45px;
	font-weight: 900;
	color: var(--dark-color);
	text-shadow: 1px 1px 4px rgb(222, 220, 220);
text-align: center;
word-wrap: break-word;
}

.buttons-main {
	display: flex;
	justify-content: center;
position: relative;
margin-top: 2%;
margin-right: auto;
margin-left: auto;
}

.btn-abouteb3 {
	border: 3px solid #244B7A;
	background: none;
	border-radius: 10px;
	width: 250px;
	height: 50px;
	color: var(--dark-color);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
margin-right: 10px;
}

.btn-abouteb3:hover {
	background: #244B7A;
	transition-delay: var(--color);
	color: var(--light-color);
}

.btn-level-anketa-l {
	border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 250px;
	height: 50px;
	color: var(--light-color);
	font-weight: 500;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
	
}

.btn-level-anketa-l:hover {
	background: var(--dark-color);
	color: var(--light-color);
} 


.vie {
	background-image: url(../../../public/images/bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-x: center;
	/* filter: grayscale(0.2); */
	position: relative;
	width: 100%;
	height: 50vh;
}

.vie-mini{
	background-image: url(../../../public/images/bg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position-x: center;
	/* filter: grayscale(0.2); */
	position: relative;
	width: 100%;
	height: 20vh;
}

a {
	text-decoration: none;
}

.blok {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
	background: transparent;
	background-color: rgba(248, 247, 247, 0.1);
	margin-top: 2%;
	margin-bottom: -10%;
}

.place {
	padding: 70px;
	flex: 1;
}

.text {
display: flex;
justify-content: center;
	font-size: 18px;
	font-weight: 200;
	text-align:start;
	font-family: 'Merriweather', serif;
}

.grey {
	color: #e1e0e0;
}

.grey-dark {
	color: #1f1e1e;
}

.blue {
	background: var(--main-color);
}

.light {
	background: var(--light-color);
}

.iconwhite {
	color: #fff;
}

.iconblue {
	color: #353c4e;
}

.par {
	color: #353c4e;
	font-family: 'Roboto', sans-serif;
	font-size: 26px;
	font-weight: 700;
	text-decoration: none;
	text-align: center;
}

.white {
	color: var(--light-color);
}

.zoom {
	transition: transform .5s;
}

.zoom:hover {
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
	transition: 0.5s ease-in-out;
	cursor: pointer;
}

.rond {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	transition: transform .5s;
}

.rond:hover {
	-ms-transform: scale3d(1.1, 1.1, 1.1);
	-webkit-transform: scale3d(1.1, 1.1, 1.1);
	transform: scale3d(1.1, 1.1, 1.1);
	transition: 0.5s ease-in-out;
	cursor: pointer;
	border: 2px solid #bd8e55;
}


.titrecenter {
	display: flex;
	justify-content: center;
	height: 160px;
	margin-top: 6%;
}

.titrecenter-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 160px;
	margin-top: 6%;
}

.titre-column {

	font-size: 30px;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	margin-top: -2%;
}

.titre {
	color: var(--dark-color);
	font-size: 50px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
}

.grid {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	margin-top: 5%;
	margin-bottom: 2%;
}

.grid-future {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;

	margin-bottom: 8%;
	
}
.level {
	background-image: url(../../../public/images/level.jpg);
	transition: transform .1s;
	box-shadow: 0px 0px 8px 4px #b5b3b3;
	background-size: cover;
	background-position: center;
    background-repeat: no-repeat;
	position: relative;
	border-radius: 8px;
	margin-bottom: 10%;
	padding: 20px;

}

.levellev:hover {
	transform: scale(1.1);
}


.levellev{
	flex-direction: column;
	justify-content: center;
	border-radius: 8px;
	padding: 30px;
	background-image: url(../../../public/images/level.jpg);
	transition: transform .1s;
	background-position-x: center;
	background-size: cover;
	width: 455px;
max-height:10000px;
	cursor: pointer;
	box-shadow: 0px 0px 8px 4px #b5b3b3;
	text-align: start;
	position: relative;
	margin-bottom: 8%;
overflow: hidden;
}

.level-future-bot {
	flex-direction: column;
	justify-content: center;
	border-radius: 8px;
	padding: 30px;
	background-image: url(../../../public/images/level.jpg);
	
	transition: transform .1s;
	background-position-x: center;
	background-size: cover;
	width: 650px;
	max-height: 8000px;
	cursor: pointer;
	box-shadow: 0px 0px 8px 4px #b5b3b3;
	margin-top: 5%;
	overflow: hidden;
}

.text-future-bot{
	text-align: start;
margin-bottom: 10%;
}

.level-future:hover {
	transform: scale(1.1);
}

.price-all{
	display: flex;
	justify-content: space-around;
}
.coming{
	background-color: var(--color);
	padding: 20px;
	margin-right: -5%;
	margin-top: -1%;
	width: 200px;
	color: var(--light-color);
	font-size: 22px;
margin-left: auto;
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;

}

.coming-lev2{
	background-color: var(--color);
	padding: 20px;
	margin-right: -8%;
	margin-top: -1%;
	width: 200px;
	color: var(--light-color);
	font-size: 22px;
margin-left: auto;
border-top-left-radius: 6px;
border-bottom-left-radius: 6px;
}
.appliName {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 40px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
	position: relative;
	text-shadow: 1px 1px 3px rgb(222, 220, 220);
}
.appliName-future {
	margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 40px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
	position: relative;
	text-shadow: 1px 1px 3px rgb(222, 220, 220);
}
.appliName-names {
	margin-top: 8px;
	margin-bottom:6px;
	text-align: center;
	font-size: 27px;
	font-weight: bolder;
	color: var(--dark-color);
	position: relative;

}
.center-level{
	display: flex;

	justify-content: center;
	align-items: center;
}
.btn-levels {
	border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 240px;
	height: 50px;
	color: var(--light-color);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
	/* left: 50%;
	transform: translate(-50%, 0); */
left: 25%;
	bottom: 30px;
	margin-bottom: -4%;
	position: absolute;


}

.btn-levels:hover {
	background: var(--dark-color);
	color: var(--light-color);
}

.price-del {
	margin-top: 10px;
	font-size: 30px;
	font-weight: 500;
	color: var(--deep-dark);
	text-transform: uppercase;
	position: relative;
	margin-right: 1%;
}

.price {
	margin-top: 10px;
	text-decoration: none;
	font-size: 40px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;
	position: relative;
	margin-left: 1%;
}
.price-all {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	text-decoration: none;
	font-size: 40px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;
	position: relative;
}

.appliName-levelOne {
	margin-top: 10px;
	text-align: center;
	font-size: 40px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;
	position: relative;
}
.appliName-level-two{
	margin-top: 10px;
	text-align: center;
	font-size: 35px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;
	position: relative;
}
.appliName-levelOne-del {
	margin-top: 10px;
	text-align: center;
	font-size: 35px;
	font-weight: 500;
	color: var(--dark-color);
	text-decoration: line-through;
	text-transform: uppercase;
	position: relative;
}
.appliName-levelOne-free{
	font-size: 40px;
	left: 50%;
	transform: translate(-50%, 0);
}




.coming-bonus{
	background: none;
text-align: center;

	margin-bottom: -1%;
margin-right: auto;
font-weight: 600;
	color: var(--color);
	font-size: 26px;
}

.coming-bonus-two{
	background: none;
	border: 3px solid #CF3549;
font-weight: 600;
	color: var(--deep-dark);
	font-family: 'Merriweather', serif;
	font-size: 19px;
	padding: 8px;
	word-wrap: break-word;
}
.description-future {
	display: flex;
	color: var(--deep-dark);
	font-size: 19px;
	margin-top: 2%;
	font-weight: 600;
margin-left: 2%;	
font-family: 'Merriweather', serif;
line-height: 24px;
word-wrap: break-word;
}

.description-future-bot {
	display: flex;
	color: var(--deep-dark);
	font-size: 19px;
	margin-top: 2%;
	font-weight: 600;
margin-left: 2%;	
font-family: 'Merriweather', serif;
line-height: 24px;
}

.description-future-finaly{
	margin-bottom: 80px;
}

.description-future-finaly-bot{
	margin-bottom: 10%;
}
.text-discription-future {

	width: 100%;
	margin-bottom: 1%;
	word-wrap: break-word;
	}

	.text-discription-future-bot {

		width: 100%;
		margin-bottom: 1%;
		}



/*rewievs*/

.product {
	position: relative;
	overflow: hidden;
	padding: 20px;
	margin-bottom: 10%;
	margin-top: 2%;
}
.star{
	display: flex;
}
.stars-auto{
	margin-left: auto;
	align-self: center;
}
.stars{
margin: 6px;


}
.product-container {
	padding: 0 10vw;
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
}

.product-container::-webkit-scrollbar {
	display: none;
}

.product-info {
	display: flex;
	flex-direction: column;
}

.product-card {
	flex: 0 0 auto;
	width: 440px;
	height: 480px;
	margin-right: 26px;
	background-color: var(--light-color);
	border: #e2e1e4 4px solid;
	overflow: hidden;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	border-radius: 8px;

}

.name {
	font-size: 22px;
	font-weight: 800;
	font-family: 'Merriweather', serif;
}

.review {
	font-size: 18px;
	font-weight: 400;
	text-align: start;
	font-family: 'Merriweather', serif;

}

.product-thumb {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.discount-tag {
	position: absolute;
	background: #fff;
	padding: 5px;
	border-radius: 5px;
	color: #ff7d7d;
	right: 10px;
	top: 10px;
	text-transform: capitalize;
}

.card-btn {
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
	padding: 10px;
	width: 90%;
	text-transform: capitalize;
	border: none;
	outline: none;
	background: #fff;
	border-radius: 5px;
	transition: 0.5s;
	cursor: pointer;
	opacity: 0;
}

.product-card:hover .card-btn {
	opacity: 1;
}

.card-btn:hover {
	background: #ff7d7d;
	color: #fff;
}

.product-info {
	width: 100%;
	height: 100px;
	padding-top: 10px;
}

.product-brand {
	text-transform: uppercase;
}

.product-short-description {
	width: 100%;
	height: 20px;
	line-height: 20px;
	overflow: hidden;
	opacity: 0.5;
	text-transform: capitalize;
	margin: 5px 0;
}

.actual-price {
	margin-left: 20px;
	opacity: 0.5;
	text-decoration: line-through;
}


.nxt-btn {
	border: none;
	width: 10vw;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(90deg, rgba(20, 1, 191, 0) 0%, #fff 150%);
	cursor: pointer;
	z-index: 8;
}

.pre-btn {
	left: 0;
	border: none;
	width: 10vw;
	height: 100%;
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(20deg, rgba(20, 1, 191, 0) 0%, #ffffff 100%);
	cursor: pointer;
	z-index: 8;
}

.nxt-btn {
	right: 0;
}


.pre-btn img,
.nxt-btn img {
	opacity: 0.2;
}

.pre-btn:hover img,
.nxt-btn:hover img {
	opacity: 1;
}

/*contacts*/



.contacts-map {
	display: flex;
	background-color: var(--main-color);
	height: 100%;
	justify-content: space-evenly;
}

.map{
height:250px;
width: 100%;
align-self: center;
}

.cont{
display: flex;
justify-content: center;
width: 100%;
height: 250px;
}

.contact-name{
	color: var(--light-color);
	font-size: 24px;
	text-transform: uppercase;
}

.number-phone{
	display: flex;
	padding: 2px;
}

.number{
	margin-top: 0.6%;
	margin-left: 1.6%;
	color: var(--light-color);
	font-size: 16px;
	font-weight: lighter;
	font-family: 'Merriweather', serif;
}

.link-column{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 12%;
}
.align{
	align-self: center;
	max-width: 380px;
}
/*our friends*/

.partners {
	height: 260px;
	background-color: #D2D1D6;
	margin-top: -2%;
}

.friends {
	height: 260px;
	background-color: #dcdcdc;
}

.titre-partners {
	color: var(--dark-color);
	font-family: 'Playfair Display', serif;
	font-size: 26px;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.logo-partners {
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: 3%;
}

.teachbk {
	display: flex;
	flex-direction: column;
}

.teach {
	font-size: 18px;
	text-align: center;
	margin-top: -0.1%;
	color: #424242;
}

.footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	background-color: var(--main-color);
	max-height: 960px;
}
.eb3-un{
	word-wrap: normal;
}
.marg {
	font-size: 18px;
	margin-top: -10%;
}

.parr4 {
	color: #ffffff;
	text-transform: uppercase;
	font-size: 20px;
	font-family: 'Oranienbaum', serif;
}

.light-color {
	color: var(--light-color);
}

.aboutUs,
.contacts {
	display: flex;
	flex-direction: column;
	margin-top: 5%;
}

.links {
	margin-left: -5%;
}

.link-margin {
	margin: 10px;
}

.hr-contact{
	width: 100%;
border: none;

	background-color: var(--light-color);
}

.created{
	background-color: #244B7A;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	margin-bottom: -1%;
	
}

.created-we{
	display: flex;
	justify-content: center;
	background-color: #244B7A;
}
.we{
margin: 6px;
font-family: 'Playfair Display', serif;
	color: var(--light-color);
	font-weight: 400;
	align-items: center;
	font-size: 19px;
}
.we-creat{
	font-family: 'Playfair Display', serif;
	color: var(--light-color);
	font-weight: lighter;
margin-bottom: -0.3%;
}

@media screen and (max-width: 1920px){
	.level-future {
	height: 500px;
		margin-bottom: 1%;
	}


}

@media screen and (max-width: 1600px){

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}

@media screen and (max-width: 1580px) {
	.fly {
		position: absolute;
		top: 10%;
		text-align: center;
	}

	#first {
		font-family: 'Roboto', sans-serif;
		font-size: 100px;
		font-weight: 900;
		color: var(--deep-dark);
		letter-spacing: 0.4;
	text-align: center;
}

	#second {
		font-family: 'Roboto', sans-serif;
		font-size: 80px;
		font-weight: 900;
		color: var(--main-color);
		margin-top: -8%;

	}

	#third {
		font-family: 'Roboto', sans-serif;
		font-size: 60px;
		font-weight: 900;
		color: var(--deep-dark);
		margin-top: -6%;
	}

	.buttons {
		margin-top: 2%;


	}

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}

@media screen and (max-width: 1550px) {
	.fly {
		position: absolute;
		top: 10%;
		text-align: center;
	}

	#first {
		font-family: 'Roboto', sans-serif;
		font-size: 100px;
		font-weight: 900;
		color: var(--deep-dark);
		letter-spacing: 0.4;
	text-align: center;
}

	#second {
		font-family: 'Roboto', sans-serif;
		font-size: 80px;
		font-weight: 900;
		color: var(--main-color);
		margin-top: -8%;

	}

	#third {
		font-family: 'Roboto', sans-serif;
		font-size: 60px;
		font-weight: 900;
		color: var(--deep-dark);
		margin-top: -6%;
	}

	.buttons {
		margin-top: 2%;


	}

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}
@media screen and (max-width: 1520px) {
	.fly {
		position: absolute;
		top: 10%;
		text-align: center;
	}

	#first {
		font-family: 'Roboto', sans-serif;
		font-size: 100px;
		font-weight: 900;
		color: var(--deep-dark);
		letter-spacing: 0.4;
	text-align: center;
}

	#second {
		font-family: 'Roboto', sans-serif;
		font-size: 80px;
		font-weight: 900;
		color: var(--main-color);
		margin-top: -8%;

	}

	#third {
		font-family: 'Roboto', sans-serif;
		font-size: 60px;
		font-weight: 900;
		color: var(--deep-dark);
		margin-top: -6%;
	}

	.buttons {
		margin-top: 2%;


	}

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}
@media screen and (max-width: 1500px) {
	.fly {
		position: absolute;
		top: 10%;
		text-align: center;
	}

	#first {
		font-family: 'Roboto', sans-serif;
		font-size: 100px;
		font-weight: 900;
		color: var(--deep-dark);
		letter-spacing: 0.4;
	text-align: center;
}

	#second {
		font-family: 'Roboto', sans-serif;
		font-size: 80px;
		font-weight: 900;
		color: var(--main-color);
		margin-top: -8%;

	}

	#third {
		font-family: 'Roboto', sans-serif;
		font-size: 60px;
		font-weight: 900;
		color: var(--deep-dark);
		margin-top: -6%;
	}

	.buttons {
		margin-top: 2%;


	}

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}

@media screen and (max-width: 1480px) {
	.fly {
		position: absolute;
		top: 10%;
		text-align: center;
	}

	#first {
		font-family: 'Roboto', sans-serif;
		font-size: 100px;
		font-weight: 900;
		color: var(--deep-dark);
		letter-spacing: 0.4;
	text-align: center;
}

	#second {
		font-family: 'Roboto', sans-serif;
		font-size: 80px;
		font-weight: 900;
		color: var(--main-color);
		margin-top: -8%;

	}

	#third {
		font-family: 'Roboto', sans-serif;
		font-size: 60px;
		font-weight: 900;
		color: var(--deep-dark);
		margin-top: -6%;
	}

	.buttons {
		margin-top: 2%;


	}

	.btn-eb3 {
		border: 3px solid #244B7A;
		background: none;
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--dark-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
	}

	.btn-eb3:hover {
		background: #244B7A;
		transition-delay: var(--color);
		color: var(--light-color);
	}

	.btn-level {
		border: none;
		background: var(--main-color);
		border-radius: 10px;
		width: 240px;
		height: 50px;
		color: var(--light-color);
		font-weight: 600;
		text-transform: uppercase;
		font-size: 16px;
		cursor: pointer;
		margin-left: 2%;
	}

	.btn-level:hover {
		background: var(--dark-color);
		color: var(--light-color);
	}
}
@media screen and (max-width: 1380px){

.grid{
	display: flex;
	flex-wrap: wrap;
}

#second-text{
	font-size: 48px;
}

#third-text{
	font-size: 48px;
}
}
@media screen and (max-width: 1280px) {
	
	.photoMain{
		height: 850px;
	}
	.text{
		word-spacing: 2px;
		word-break: initial;
	}
		.fly {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
	
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}

@media screen and (max-width: 1260px) {
	
	  .header-level{
		z-index: 1;
	}
	.text{
margin-left: auto;
margin-right: auto;
max-width: 740px;
		word-break: initial;
	}
		.fly {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}

	.place{
		flex: auto;
		padding: 28px;
	}
}
@media screen and (max-width: 1240px) {
	
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 18px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
	}
	
	.header-level{
		z-index: 1;
	}
	.header-nav-items{
		line-height: 1;
		font-family: 'Roboto', sans-serif;
		  font-weight: 700;
		  font-size: 20px;
		  text-decoration: none;
		  color: var(--dark-color);
		  padding: 10px 26px;
		align-items: center;
		cursor: pointer;
		  transition: transform .5s;
	  }
	.text{
		word-spacing: 2px;
		word-break: initial;
	}
		.fly {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}

@media screen and (max-width: 1220px) {
	
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 18px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
	}
	
	.header-level{
		z-index: 1;
	}
	.header-nav-items{
		line-height: 1;
		font-family: 'Roboto', sans-serif;
		  font-weight: 700;
		  font-size: 20px;
		  text-decoration: none;
		  color: var(--dark-color);
		  padding: 10px 26px;
		align-items: center;
		cursor: pointer;
		  transition: transform .5s;
	  }
	.text{
		word-spacing: 2px;
		word-break: initial;
	}
		.fly-text {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}

@media screen and (max-width: 1200px) {
	
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 18px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
	}
	
	.header-level{
		z-index: 1;
	}
	.header-nav-items{
		line-height: 1;
		font-family: 'Roboto', sans-serif;
		  font-weight: 700;
		  font-size: 20px;
		  text-decoration: none;
		  color: var(--dark-color);
		  padding: 10px 21px;
		align-items: center;
		cursor: pointer;
		  transition: transform .5s;
	  }

	  .header-nav-list{
		flex: 1;
	  }
	.text{
		word-spacing: -1px;

	}

.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}
@media screen and (max-width: 1196px) {
	
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 12px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
		margin-right: 20%;
	}
	.header-nav{
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
		
	  }
	
	  .header-nav.active{
		transform: translateX(100%);
	  }
	
	  .header-nav-list{
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-top: 10px;
	
	  }
	
	  .header-nav-item{
		text-align: center;
		align-items: center;
		font-weight: 600;
		font-size: 20px;
		padding: 16px 26px;
	  }
	
	  .header-nav-item:nth-child(n + 2){
		margin-left: 0;
	  }
	
	  .header-menu-button{
		display: block;
		margin-left: auto;
		background-color: transparent;
		padding: 0;
		border: 0;
	  }

	  	
	.header-level{
		z-index: 1;
	}
	  .ite
	.text{
		word-spacing: -1px;

	}
		.fly-text {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}

@media (max-width:1156px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }

    }

@media screen and (max-width: 1156px) {
	.header-nav{
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
		
	  }
	
	  .header-nav.active{
		transform: translateX(100%);
	  }
	
	  .header-nav-list{
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-top: 10px;
	
	  }
	
	  .header-nav-item{
		text-align: center;
		align-items: center;
		font-weight: 600;
		font-size: 20px;
		padding: 16px 26px;
	  }
	
	  .header-nav-item:nth-child(n + 2){
		margin-left: 0;
	  }
	
	  .header-menu-button{
		display: block;
		margin-left: auto;
		background-color: transparent;
		padding: 0;
		border: 0;
	  }
	
	.header-level{
		z-index: 1;
	}
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 18px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
	}

	.header-nav-items{
		line-height: 1;
		font-family: 'Roboto', sans-serif;
		  font-weight: 700;
		  font-size: 20px;
		  text-decoration: none;
		  color: var(--dark-color);
		  padding: 10px 16px;
		align-items: center;
		cursor: pointer;
		  transition: transform .5s;
	  }
	.text{
		word-spacing: -1px;

	}
		.fly-text {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;
}
}
@media screen and (max-width: 1080px) {
	.header-nav{
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
			  }
	
	  .header-nav.active{
		transform: translateX(100%);
	  }
	
	.header-level{
		z-index: 1;
	}
	.photoMain{
		height: 850px;
	}
	.item-buttons {
		font-family: 'Roboto', sans-serif;
		font-weight: 100;
		font-size: 18px;
		text-decoration: none;
		color: var(--dark-color);
		padding: 18px 35px;
		text-align: center;
	}

	.header-nav-items{
		line-height: 1;
		font-family: 'Roboto', sans-serif;
		  font-weight: 700;
		  font-size: 20px;
		  text-decoration: none;
		  color: var(--dark-color);
		  padding: 10px 16px;
		align-items: center;
		cursor: pointer;
		  transition: transform .5s;
	  }
	.text{
		word-spacing: -1px;

	}
		.fly-text {
		position: absolute;
		top: 18%;
		text-align: center;
	}
.description{
	word-spacing: 6px;
}
	.center{
		text-align: center;
		margin-left: -6%;
	}

	
.contacts-map {
	display: flex;
	flex-direction: column;
}
.align{
margin-top: 10%;
max-height: 500px;

}
.cont{
	display: flex;
	flex-direction: column;
	height: 380px;

	}
	.contact-name{
text-align: center;
	}
	.link-column{
		display: flex;
flex-direction: row;
margin-bottom: 10%;
margin-left: 0%;

	}
}

@media screen and (max-width: 1025px) {
	.header-nav{
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
		
	  }
	
	  .header-nav.active{
		transform: translateX(100%);
	  }
	
	
	.header-level{
		z-index: 1;
	}

	.header-nav-items{
		font-size: 20px;
		padding: 10px 11px;
	}
.photoMain{
	height: 750px;
}


	.buttons {
		margin-top: -2%;
		margin-left: -8%;
	}

	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 8%;
		font-size: 56px;
		line-height: 36px;
	}
	#second-text {
		font-size: 40px;
		line-height: 26px;
	}
	
	#third-text {
		font-size: 40px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-left: -2px;
	}
	.text {
		font-size: 16px;
		font-weight: 200;
	}
.place{
	flex: auto;
}
	.place {
		padding: 30px;
	}

}

@media screen and (max-width: 1000px) {
	
	
	.header-nav{
		position: fixed;
		top: 0;
		left: -225px;
		display: flex;
		flex-direction: column;
		width: 220px;
		height: 100%;
		background-color: var(--light-color);
		box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
		transition: transform 0.3s linear;
		
	  }
	
	  .header-nav.active{
		transform: translateX(100%);
	  }
	.header-level{
		z-index: 1;
	}

	.header-nav-items{
		font-size: 20px;
		padding: 10px 10px;
	}
.photoMain{
	height: 750px;
}


	.buttons {
		margin-top: -2%;
		margin-left: -8%;
	}

	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 8%;
		font-size: 56px;
		line-height: 36px;
	}
	#second-text {
		font-size: 40px;
		line-height: 26px;
	}
	
	#third-text {
		font-size: 40px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-left: -2px;
	}
	.text {
		font-size: 16px;
		font-weight: 200;
	}

	.place {
		padding: 30px;
	}

}
@media (max-width:926px) {
  .header-nav{
    position: fixed;
    top: 0;
    left: -225px;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    background-color: var(--light-color);
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s linear;
    
  }

  .header-nav.active{
    transform: translateX(100%);
  }

  .header-nav-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

  }

  .header-nav-item{
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    padding: 16px 26px;
  }

  .header-nav-item:nth-child(n + 2){
    margin-left: 0;
  }

  .header-menu-button{
    display: block;
    margin-left: auto;
    background-color: transparent;
    padding: 0;
    border: 0;
  }
.photoMain{
	height: 600px;
}

.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}

.titre{
	font-size: 34px;
}
.titre-column {
	font-size: 26px;
	font-weight: 400;
}
.text {
	font-size: 16px;
	font-weight: 200;
}
.level-item{
	padding: 28px;
}
.level-future{
	height: 540px;
}
}

@media (max-width:916px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }
	.fly-text {
	  position: absolute;
	  margin-top: -24%;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }

  @media (max-width:906px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }

  @media (max-width:896px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }

  @media (max-width:886px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  .level-future{
	height: 540px;
  }
  }

  @media (max-width:876px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }
  @media (max-width:860px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }
	
  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }
  @media (max-width:842px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 1560px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 40px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }
	
  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 30px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }

  @media (max-width:820px) {
	.header-nav{
	  position: fixed;
	  top: 0;
	  left: -225px;
	  display: flex;
	  flex-direction: column;
	  width: 220px;
	  height: 100%;
	  background-color: var(--light-color);
	  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
	  transition: transform 0.3s linear;
	  
	}
  
	.header-nav.active{
	  transform: translateX(100%);
	}
  
	.header-nav-list{
	  flex: 1;
	  display: flex;
	  flex-direction: column;
	  padding-top: 10px;
  
	}
  
	.header-nav-item{
	  text-align: center;
	  align-items: center;
	  font-weight: 600;
	  font-size: 20px;
	  padding: 16px 26px;
	}
  
	.header-nav-item:nth-child(n + 2){
	  margin-left: 0;
	}
  
	.header-menu-button{
	  display: block;
	  margin-left: auto;
	  background-color: transparent;
	  padding: 0;
	  border: 0;
	}
  .photoMain{
	  height: 600px;
  }

  .first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 8%;
	font-size: 44px;
	line-height: 32px;
}
#second-text {
	font-size: 36px;
	line-height: 26px;
}

#third-text {
	font-size: 36px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
  .titre{
	  font-size: 34px;
  }
  .titre-column {
	  font-size: 26px;
	  font-weight: 400;
  }
  .text {
	  font-size: 16px;
	  font-weight: 200;
  }
  .level-item{
	  padding: 28px;
  }
  }

@media (max-width:806px){
	.header-level{
		z-index: 1;
	}
	.fly {
		position: absolute;
		top: 30%;
	}

		.buttons {
			margin-top: 10%;
		margin-left: -8%;
	}

	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 8%;
		font-size: 44px;
		line-height: 30px;
	}
	#second-text {
		font-size: 36px;
		line-height: 26px;
	}
	
	#third-text {
		font-size: 36px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-left: -2px;
	}

	.block{
		display: flex;
		flex-direction: column;
	}

	.level-future{

	}
}
@media (max-width:786px){
	.header-level{
		z-index: 1;
	}

	.place {
		padding: auto;
		flex: auto;
	}
	.fly-text {
		position: absolute;
		top: 30%;
	}

		.buttons {
			margin-top: 10%;
		margin-left: -8%;
	}


	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 34px;
	}

	#third-text {
		font-size: 34px;
	}

	.block{
		display: flex;
		flex-direction: column;
	}
}
@media (max-width:769px){
	.place {
		padding: auto;
		flex: auto;
	}
	
	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 34px;
	}

	#third-text {
		font-size: 34px;
	}

	.fly-text{
		margin-top: -20%;
	}
.level-future{
	height: 540px;
}
}
@media (max-width:740px){
	.place {
		padding: auto;
		flex: auto;
	}
}

@media (max-width:720px){
	.place {
		padding: auto;
		flex: auto;
	}

	
	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 30px;
	}

	#third-text {
		font-size: 30px;
	}
	.level-future{
		height: 580px;
	}
}
@media (max-width:706px){
	.place {
		padding: auto;
		flex: auto;
	}

	#first-text{
		margin-top: 5%;
	}
}

@media (max-width:702px){
	#first-text {
		font-size: 38px;
		margin-top: 10%;
		text-align: center;
	}
}

@media (max-width:695px){
	.place {
padding: auto;
		flex: auto;
	}

	
	#first-text {
		font-size: 38px;
		margin-top: 5%;
		text-align: center;
	}

	#second-text {
		font-size: 24px;
	}

	#third-text {
		font-size: 24px;
	}
}

@media (max-width:685px){
	.place {
padding: auto;
		flex: auto;
	}

	
	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 30px;
	}

	#third-text {
		font-size: 30px;
	}
}

@media (max-width:675px){
	.place {
		padding: auto;
				flex: auto;
			}
		

	
	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 30px;
	}

	#third-text {
		font-size: 30px;
	}
}

@media (max-width:660px){
	.place {
padding: auto;
		flex: auto;
	}

	
	#first-text {
		font-size: 43px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 30px;
	}

	#third-text {
		font-size: 30px;
	}
}

@media (max-width:656px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 600px;
}
	
	#first-text {
		font-size: 43px;
		margin-top: 7%;
		text-align: center;
	}

	#second-text {
		font-size: 30px;
	}

	#third-text {
		font-size: 30px;
	}
}

@media (max-width:646px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
	
	#first-text {
		font-size: 40px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 28px;
	}

	#third-text {
		font-size: 28px;
	}
}

@media (max-width:636px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
	
	#first-text {
		font-size: 40px;
		margin-top: 10%;
		text-align: center;
	}

	.level-future:hover{
		transform: none;
	}
	#second-text {
		font-size: 28px;
	}

	#third-text {
		font-size: 28px;
	}
}

@media (max-width:626px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
	
	#first-text {
		font-size: 40px;
		margin-top: 10%;
		text-align: center;
	}

	#second-text {
		font-size: 28px;
	}

	#third-text {
		font-size: 28px;
	}
}

@media (max-width:616px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 10%;
	font-size: 38px;
	line-height: 26px;
}
#second-text {
	font-size: 34px;
	line-height: 26px;
}

#third-text {
	font-size: 30px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
}

@media (max-width:606px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 36px;
	line-height: 26px;
}
#second-text {
	font-size: 30px;
	line-height: 24px;
}

#third-text {
	font-size: 28px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
	

}

@media (max-width:596px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
		.product-card{
		height: 460px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 32px;
		line-height: 24px;
	}
	#second-text {
		font-size: 30px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 28px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-left: -2px;
	}
}


@media (max-width:586px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
.coming{
	margin-right: -6%;
}
	
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 32px;
	line-height: 24px;
}
#second-text {
	font-size: 30px;
	line-height: 24px;
}

#third-text {
	font-size: 28px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
	
	
}

@media (max-width:576px){
	.vie{
		background-image: none;
		background-color: #244B7A;
		height: 10vh;
	}
	
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
.coming{
	margin-right: -6%;
}
	
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 32px;
	line-height: 24px;
}
#second-text {
	font-size: 30px;
	line-height: 24px;
}

#third-text {
	font-size: 28px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
}

@media (max-width:566px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 550px;
}
.coming{
	margin-right: -6%;
}
	
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 32px;
	line-height: 24px;
}
#second-text {
	font-size: 28px;
	line-height: 24px;
}

#third-text {
	font-size: 28px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
}

@media (max-width:556px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 540px;
}

.level-future:hover{
	transform: none;
}

.levellev:hover{
	transform: none;
}
.coming{
	margin-right: -6%;
}

.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 32px;
	line-height: 24px;
}
#second-text {
	font-size: 28px;
	line-height: 24px;
}

#third-text {
	font-size: 28px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
	
}

@media (max-width:546px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 530px;
}

.price-all{
	display: flex;
	flex-direction: column;
}
.coming{
	margin-right: -6%;
}
.appliName-level-two{
	margin-top: -12%;
}
#first-text {
	font-size: 32px;
	margin-top: -1%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 24px;
	line-height: 24px;
}

#third-text {
	font-size: 24px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}
	
	
}

@media (max-width:536px){
	.place {
padding: auto;
		flex: auto;
	}
.level-future{
	width: 510px;
}
.coming{
	margin-right: -6%;
}
#first-text {
	font-size: 32px;
	margin-top: -1%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 24px;
	line-height: 24px;
}

#third-text {
	font-size: 24px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-left: -2px;
}

}

@media (max-width:525px){
	.place {
padding: auto;
		flex: auto;
	}
	.coming{
		margin-right: -7%;
	}
	.photoMain{
		height: 550px;
	}
.level-future{
	width: 510px;

}
#first-text {
	font-size: 32px;
	margin-top: -1%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 26px;
	line-height: 24px;
}

#third-text {
	font-size: 26px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		margin-top: -1%;
		padding: 10px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}
}

@media (max-width:516px){
	.place {
padding: auto;
		flex: auto;
	}
	.coming{
		margin-right: -7%;
	}
	.photoMain{
		height: 580px;
	}
.level-future{
	width: 510px;

}
#first-text {
	font-size: 32px;
	margin-top: -20%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 26px;
	line-height: 24px;
}

#third-text {
	font-size: 26px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		margin-top: -1%;
		padding: 10px;
	}
.we{
	margin-bottom: 10%;
}

	.btn-abouteb3{
		color: var(--light-color);
	}
}
@media (max-width:506px){
	.place {
padding: auto;
		flex: auto;
	}
	.coming{
		margin-right: -7%;
	}
	.photoMain{
		height: 600px;
	}
.level-future{
	width: 480px;
}
#first-text {
	font-size: 32px;
	margin-top: -2%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 26px;
	line-height: 24px;
}

#third-text {
	font-size: 26px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}
}

@media (max-width:496px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 600px;
	}
.level-future{
	width: 480px;
}
	
#first-text {
	font-size: 32px;
	margin-top: 10%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 26px;
	line-height: 24px;
}

#third-text {
	font-size: 26px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}
	.coming{
		margin-right: -7%;
	}

	.product-card {
		width: 370px;
		height:500px;
	}
}

@media (max-width:486px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 600px;
	}
.level-future{
	width: 480px;
}
	
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 28px;
		line-height: 24px;
	}
	#second-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}
	.product-card {
		width: 370px;
		height:500px;
	}
	.coming{
		margin-right: -7%;
	}
}
@media (max-width:476px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 600px;
	}
.level-future{
	width: 460px;

}
	
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 28px;
		line-height: 24px;
	}
	#second-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}

@media (max-width:466px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}
.level-future{
	width: 460px;
}
	
#first-text {
	font-size: 32px;
	margin-top: 10%;
	text-align: center;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 28px;
	line-height: 24px;
}
#second-text {
	font-size: 26px;
	line-height: 24px;
}

#third-text {
	font-size: 26px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 140px;
		height: 65px;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}


@media (max-width:460px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}


	.levellev{
		width: 445px;
	}
	.btn-levels{
		left: 22%;
	}
.level-future{
	width: 440px;
}
	
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 28px;
		line-height: 24px;
	}
	#second-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 26px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 140px;
		height: 65px;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}

@media (max-width:446px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 3%;
		}
		.levellev{
			width: 435px;
		}

		.btn-levels{
			left: 21%;
		}
.level-future{
	width: 440px;
}
	
	#first-text {
		font-size: 32px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 26px;
		line-height: 24px;
	}
	#second-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 140px;
		height: 65px;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}

@media (max-width:436px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 6%;
		}
		.levellev{
			width: 420px;
		}
		.btn-levels{
			left: 21%;
		}
.level-future{
	width: 430px;
}
	
	#first-text {
		font-size: 28px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 26px;
		line-height: 24px;
	}
	#second-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 120px;
		height: 55px;
	}

	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}

@media (max-width:426px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 6%;
		}
		.levellev{
			width: 410px;
		}
		.btn-levels{
			left: 19%;
		}
.level-future{
	width: 420px;
}
	
	#first-text {
		font-size: 28px;
		margin-top: 10%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 26px;
		line-height: 24px;
	}
	#second-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 23px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 120px;
		height: 55px;
	}
	.appliName-names{

	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}
@media (max-width:416px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 6%;
		}
		.levellev{
			width: 400px;
		}
		.btn-levels{
			left: 19%;
		}
.level-future{
	width: 410px;
}
	
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 26px;
	line-height: 24px;
}
#second-text {
	font-size: 23px;
	line-height: 24px;
}

#third-text {
	font-size: 23px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.product-card {
		width: 380px;
		height:500px;
	}
}
@media (max-width:406px){
	.place {
padding: auto;
		flex: auto;
	}

	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 10%;
		}
		.levellev{
			width: 395px;
		}
		.btn-levels{
			left: 18%;
		}
.level-future{
	width: 400px;
}
	
	#first-text {
		font-size: 32px;
		margin-top: 18%;
		text-align: center;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.product-card {
		width: 370px;
		height:500px;
	}
}
@media (max-width:396px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 640px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 13%;
		}
		.levellev{
			width: 375px;
		}
		.btn-levels{
			left: 17%;
		}
.level-future{
	width: 370px;
}
	
		.titre{
		font-size: 30px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}

	.name-level{
		left: 100%;
		transform: translate(-5%, 0);
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.product-card {
		width: 360px;
		height:500px;
	}
	.coming-lev2{
		margin-right: -15%;
	}
}
@media (max-width:386px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 640px;
	}
	.name-level{
		left: 100%;
		transform: translate(-1%, 0);
	}
	.text-discription {
		max-height: 1150px;
		margin-right: 16%;
		}
		.levellev{
			width: 375px;
		}
		.btn-levels{
			left: 17%;
		}
.level-future{
	width: 380px;
}
	
	.titre{
		font-size: 30px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.product-card {
		width: 360px;
		height:500px;
	}
}
@media (max-width:376px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
	.name-level{
		left: 100%;
		transform: translate(-8%, 0);
	}
	.text-discription {
		max-height: 1150px;
		margin-right: 18%;
		}
		.levellev{
			width: 360px;
		}
		.btn-levels{
			left: 16%;
		}
.level-future{
	width: 370px;
}
.price-del{
	margin-left: -1%;
}
	.titre{
		font-size: 30px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.price-del{
		margin-left: -18%;
	}
	.appliName-levelOne{
		margin-left: -5%;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.product-card {
		width: 340px;
		height:500px;
	}
}
@media (max-width:366px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 21%;
		}
		.levellev{
			width: 355px;
		}
		.btn-levels{
			left: 15%;
		}
.level-future{
	width: 360px;
}
.price-del{
	margin-left: -8%;
}
	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 22px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.product-card {
		width: 300px;
		height:600px;
	}
	.friend{
		width: 100px;
		height: 45px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.align{
		margin-left: 10%;
	}
}
@media (max-width:356px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.text-discription {
		max-height: 1150px;
		margin-right: 23%;
		}
		.levellev{
			width: 345px;
		}
		.btn-levels{
			left: 14%;
		}

		.appliName-levelOne-del {
			font-size: 32px;
		}
		.appliName-levelOne{
			font-size: 32px;
		}
		.appliName-level-two{
			font-size: 32px;
		}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-45%, 0);
		}
.level-future{
	width: 350px;
}
	

	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 22px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.align{
		margin-left: 10%;
	}
	.product-card {
		width: 300px;
		height:600px;
	}
}

@media (max-width:346px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}

	.text-discription {
		max-height: 1150px;
		margin-right: 25%;

		}
		.levellev{
			width: 335px;
		}
.btn-levels{
	left: 13%;
}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-40%, 0);
		}
.level-future{
	width: 340px;

}
	
.review{
	margin-top: 5%;
}
	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 22px;
	}
	.first-text, .second-text{
		margin-bottom: -20px;
	}
	#first-text{
		margin-top: 18%;
		font-size: 23px;
		line-height: 24px;
	}
	#second-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	#third-text {
		font-size: 22px;
		line-height: 24px;
	}
	
	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}

	.coming{
		margin-right: -8%;
	}
	.teach{
		font-size: 12px;
	}
	.align{
		margin-left: 10%;
	}
	.product-card {
		width: 300px;
		height:600px;
	}

	.coming-lev2{
		margin-right: -15%;
	}
}
@media (max-width:336px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 31%;
		font-size: 12px;
		}
		.levellev{
			width: 325px;
		}
		.level-paid{
			width: 325px;
		}
		.appliName-levelOne-del {
			font-size: 32px;
		}
		.appliName-levelOne{
			font-size: 32px;
		}
		.appliName-level-two{
			font-size: 32px;
		}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-45%, 0);
		}
.level-future{
	width: 325px;
}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 23px;
	line-height: 24px;
}
#second-text {
	font-size: 22px;
	line-height: 24px;
}

#third-text {
	font-size: 22px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}

	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 100px;
		height: 45px;
	}
	.appliName-future{
		font-size: 32px;
	}
	.name-level{
		left: 100%;
		transform: translate(-12%, 0);
	}

	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 12px;
	}
	.align{
		margin-left: 10%;
	}
.product{
	margin-top: -24%;
}
.product-card {
	width: 310px;
	height:600px;
}

.we{
font-size: 14px;
}
.btn-levels{

	left: 13%;
	}
}
@media (max-width:326px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 33%;
		font-size: 12px;
		}
		.levellev{
			width: 310px;
		}


		.appliName-levelOne-del {
			font-size: 32px;
		}
		.appliName-levelOne{
			font-size: 32px;
		}
		.appliName-level-two{
			font-size: 32px;
		}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-45%, 0);
		}
.level-future{
	width: 318px;

}
.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 23px;
	line-height: 24px;
}
#second-text {
	font-size: 22px;
	line-height: 24px;
}

#third-text {
	font-size: 22px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}

	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
		margin-left: 2%;
	}


	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 80px;
		height: 25px;
	}
	.appliName-future{
		font-size: 32px;
	}
	.appliName {
		font-size: 32px;
margin-left: -32%;
	}

	.price-del{
		margin-left: -8%;
	}
	.appliName-levelOne{
		margin-left: -5%;
	}
	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 10px;
	}
	.align{
		margin-left: 6%;
	}
.product{
	margin-top: -24%;
}
.partners{
	height: 200px;
}
.product-card {
	width: 300px;
	height:620px;
}
.btn-levels{

	left: 11%;
	}
}
@media (max-width:316px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 34%;
		font-size: 12px;
		}
		.levellev{
			width: 310px;

		}
		.level-paid{
			width: 310px;
		}

.level-future{
	width: 310px;

	margin-top: 26px;
}

.first-text, .second-text{
	margin-bottom: -20px;
}
#first-text{
	margin-top: 18%;
	font-size: 23px;
	line-height: 24px;
}
#second-text {
	font-size: 22px;
	line-height: 24px;
}

#third-text {
	font-size: 22px;
	line-height: 24px;
}

.btn-level-anketa-l{
	margin-top: 10px;
	margin-left: -2px;
}

	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 80px;
		height: 25px;
	}
	.appliName-future{
		font-size: 32px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 10px;
	}
	.align{
		margin-left: 6%;
	}
.product{
	margin-top: -24%;
}
.partners{
	height: 200px;
}
.product-card {
	width: 300px;
	height:620px;
}
.btn-levels{

	left: 11%;
	}

	.coming-lev2{
		margin-right: -15%;
	}
}
@media (max-width:306px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 36%;
		font-size: 12px;
		}
		.levellev{
			width: 300px;
		}
		.level-paid{
			width: 300px;
		}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-45%, 0);
		}
.level-future{
	width: 300px;
	margin-top: 26px;
}
.first-text, .second-text{
	margin-bottom: -20px;
}

#first-text{
	margin-top: 16%;
	font-size: 22px;
	line-height: 24px;
}
#second-text {
	font-size: 18px;
	line-height: 24px;

}

#third-text {
	font-size: 19px;
	line-height: 24px;

}

	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}
.fly-text{
	margin-top: -13%;
	line-height: 2px;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
	}

	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -2px;
	}
	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 80px;
		height: 25px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-future{
		font-size: 32px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}

	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 10px;
	}
	.align{
		margin-left: 6%;
	}
.product{
	margin-top: -24%;
}
.partners{
	height: 200px;
}
.product-card {
	width: 290px;
	height:620px;
}
.btn-levels{

	left: 10%;
	}
}
@media (max-width:296px){
	.place {
padding: auto;
		flex: auto;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 42%;
		font-size: 12px;
		}
		.levellev{
			width: 280px;

		}
		.level-paid{
			width: 280px;
		}
		.appliName-levelOne-free{
			left: 25%;
			font-size: 22px;
			transform: translate(-50%, 0);
		}
.level-future{
	width: 280px;

	margin-top: 26px;
}
.first-text, .second-text{
	margin-bottom: -20px;
}

#first-text {
	font-size: 24px;
	margin-top: 10%;
	line-height: 24px;
}

#second-text {
	font-size: 20px;
	line-height: 24px;
	
}

#third-text {
	font-size:17px;
	line-height: 24px;
	text-align: center;
}
	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}
.btn-levels{

left: 8%;
}
	.buttons-main{
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	.btn-level-anketa-l{
		margin-top: 10px;
		margin-left: -1%;

	}
	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 80px;
		height: 25px;
	}
	.appliName-future{
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 10px;
	}
	.align{
		margin-left: 4%;
	}
.product{
	margin-top: -24%;
}
.partners{
	height: 200px;
}
.product-card {
	width: 280px;
	height:620px;
}
}
@media (max-width:286px){
	.place {
padding: auto;
		flex: auto;
	}

	.first-text, .second-text{
		margin-bottom: -20px;
	}
	.photoMain{
		height: 660px;
	}
.titre-bottom{
	margin-top: 18%;
}

	.text-discription {
		max-height: 1150px;
		margin-right: 44%;
		font-size: 12px;
		}
		.levellev{
			width: 280px;

		}
		.level-paid{
			width: 270px;
		}
.level-future{
	width: 270px;

}
	#first-text {
		font-size: 24px;
		margin-top: 10%;
		line-height: 24px;
	}

	#second-text {
		font-size: 20px;
		line-height: 24px;
		
	}

	#third-text {
		font-size:17px;
		line-height: 24px;
		text-align: center;
	}
	.titre{
		font-size: 26px;
	}

	.titre-column{
		font-size: 20px;
	}

	.buttons-main{
		display: flex;
		flex-direction: column;
		padding: 30px;
margin-left: -4%;

	}

	.btn-level-anketa-l{
		margin-top: 10px;

	}
	.btn-abouteb3{
		color: var(--light-color);
	}

	.friend{
		width: 80px;
		height: 25px;
	}
	.appliName-future{
		font-size: 32px;
	}

	.btn-levels{
		left: 7%;
	}
	.price{
		font-size: 30px;
	}
	.appliName-levelOne-del {
		font-size: 32px;
	}
	.appliName-level-two{
		font-size: 32px;
	}
	.appliName-levelOne{
		font-size: 32px;
	}
	.level-future{
margin-top: 26px;
	}
	.coming{
		margin-right: -12%;
	}
	.teach{
		font-size: 10px;
	}
	.align{
		margin-left: 4%;
	}
.product{
	margin-top: -24%;
}
.partners{
	height: 200px;
}
.product-card {
	width: 290px;
	height:550px;
}
.contacts{
	margin-left: 10%;
}

.name{
font-size: 16px;
}

.stars{
	margin-left: 22%;
}
.review{
	margin-top: 14%;
	font-size: 16px;
}

.coming-lev2{
	margin-right: -15%;
}

}