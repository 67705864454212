.loading-spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh; /* Высота экрана */
  }
  
  .loading-spinner {
    font-size: 2em; /* Размер иконки */
    animation: spin 1s infinite linear;
  }
  
  .loading-text {
    margin-top: 10px; /* Расстояние между иконкой и текстом */
    font-size: 1.2em; /* Размер текста */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  