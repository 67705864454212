@font-face{
	font-family: 'Merriweather';
	src: url(../Anketa/Merriweather-Light.ttf);
}

.pool-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8%;
}
.pool-arrow{
    display: flex;
    justify-content: center;
}

 .arrow-right {
    margin: 30px;
}
.arrow-righ span {
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #244B7A;
    border-right: 5px solid #244B7A;
    transform: rotate(45deg);
    margin: -16px;
    animation: arrow-down 2s infinite;

}
.arrow-righ span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow-righ span:nth-child(3){
    animation-delay: -0.4s;
}
@keyframes arrow-righ {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}
.pool{
    margin-top: 8%;
	margin-bottom: 10px;
	text-align: center;
	font-size: 45px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
}
.hr-pool {
	width: 100%;
	height: 12px;
    margin-top: 5%;
	background-color: var(--main-color);
}
.text-pool{
    font-size: 20px;
	font-weight: 600;
  max-width:1150px;
  max-height: 5000px;
  text-align: justify;
	color: var(--deep-dark);
	font-family: 'Merriweather', serif;
  line-height: 32px;
  padding: 20px;
}

.choose-state{
    display: flex;
    justify-content: space-evenly;
    margin-top: 10%;
	margin-bottom: 10px;
	text-align: center;
	font-size: 30px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;

}

.states-ch{
    display: flex;
flex-direction: column;
justify-content: center;

}
.state-one{
    display: flex;
    justify-content: center;


}
.state-two{
    display: flex;
    justify-content: center;


}
.map-states{
    align-self: center;

}
        .mob-states{
            display: flex;
            justify-content: center;
        }
.states-right{
    margin-left: 4%;
}

.states-all{
    display: flex;
flex-direction: column;

    font-family: 'Merriweather', serif;
    font-size: 18px;
    font-weight: 700;
    margin-top: 1%;

}
.st-flex{
    display: flex;
    justify-content: center;
}
.st{
margin-top: 4%;
color: var(--deep-dark);
/* padding-right: 10px;
padding-left: 10px; */
}

.choose-all-st{
    display: flex;
    justify-content: center;
    margin-bottom: 4%;
}

.b{
    font-weight: bolder;
    color: var(--color);
}

.cards{
    display: flex;
    overflow-x: auto;
    justify-content: left;
}
.card-employer{
    background-color: var(--light-color);
    border: 2px solid #244B7A;
    width: 450px;
    max-height:780px;
   }

.location-emp{
    font-family: 'Merriweather', serif;
    font-size: 18px;
    font-weight: 800;
text-align: center;
}

.img-emp{
text-align: center;

}

.img-page{
    box-shadow: 0px 0px 2px 2px #939395;
    width:330px;
     height:350px;
}

.pad-emp{
    padding: 40px;
}

.job{
    font-family: 'Merriweather', serif;
    font-size: 16px;
    font-weight: 800;
}

.btn-job{
    border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 250px;
	height: 50px;
	color: var(--light-color);
	font-weight: 500;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
}

.btn-job:hover {
	background: var(--dark-color);
	color: var(--light-color);
} 
.state-none{
    display: block;
}


/* page jobinfo */

.job-img{
    display: flex;
    justify-content: center;
    text-align: center;
    height: auto;
margin-top: 10%;
}

.img-ny{
    text-align: center;
width: 420px;
height: 380px;
padding: 10px;

}
.crew-main{
    display: flex;
    justify-content: center;
    margin-bottom: 10%;
}
.text-job-ny{
    display: flex;
    flex-direction: column;
    justify-content: center;
align-items: center;
    background-color: var(--light-color);
    box-shadow: 0px 0px 8px 4px #b5b3b3;
    max-width: 1050px;
    padding: 80px;
}

.big-text-main{
    overflow-wrap: break-word;
}

.crew-one{
text-align: center;
font-family: 'Merriweather', serif;
font-size: 24px;
font-weight: bolder;
margin-bottom: 10%;
}

.big-text{
text-align: justify;
    max-width: 650px;
    
}
.btn-dis{
    display: flex;
    justify-content: center;
    margin-top: 8%;
}

.job-mini{
    font-family: 'Merriweather', serif;
font-size: 16px;
font-weight: 500;
}



.slide{

}


.img-slider{
margin-left: auto;
margin-right: auto;
width: 750px;
height: 450px;
}

.slider{

align-items: center;
}


.control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    padding: 0;
    text-align: center;
    width: 100%;
    z-index: 1;
    margin-top: -20%;
}

.carousel {

    position: relative;
    text-align: center;
    align-items: center;
}




@media screen and (max-width: 1025px){


    .st{
        margin-top: 3%;


        }

        .mob-states{
            display: flex;
            justify-content: center;
        }

        .states-left{
            margin-left: 5%;
        }
}

@media screen and (max-width: 806px){

.states-all{
    display: flex;
    flex-direction: column;
}
    .st{
    font-size: 15px;
}
}

@media screen and (max-width: 795px){
    .cards{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }  
    .card-employer{
        background-color: var(--light-color);
        box-shadow: 0px 0px 8px 4px #b5b3b3;
width: 100%;
        max-height:780px;
       }
}
@media screen and (max-width: 695px){
.map-states{
    width: 600px;
    height: 300px;
}
.state-none{
    display: none;
}
.mob-states{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

}
.cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.state-one{
    align-items: center;
}
}

@media screen and (max-width: 596px){
    .map-states{
        width: 500px;
        height: 220px;
    }

    .pool{
        font-size: 30px;
    }
    .cards{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .state-none{
        display: none;
    }
    }


    @media screen and (max-width: 496px){
        .map-states{
            width: 400px;
            height: 200px;
        }
        .card-employer{
            background-color: var(--light-color);
            box-shadow: 0px 0px 8px 4px #b5b3b3;
width: 100%;
            max-height:780px;
           }
           .cards{
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        }

        @media screen and (max-width: 486px){

            .card-employer{
                background-color: var(--light-color);
                box-shadow: 0px 0px 8px 4px #b5b3b3;
                width: 100%;
                max-height:780px;
               }
               .cards{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .text-job-ny{
                padding: 40px;
            }
            }
        @media screen and (max-width: 476px){

            .card-employer{
                background-color: var(--light-color);
                box-shadow: 0px 0px 8px 4px #b5b3b3;
                width: 100%;
                max-height:780px;
               }
               .cards{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            }

        @media screen and (max-width: 466px){

            .card-employer{
                background-color: var(--light-color);
                box-shadow: 0px 0px 8px 4px #b5b3b3;
                width: 100%;
                max-height:780px;
               }
               .cards{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            }

        @media screen and (max-width: 456px){

            .card-employer{
                background-color: var(--light-color);
                box-shadow: 0px 0px 8px 4px #b5b3b3;
                width: 100%;
                max-height:780px;
               }
               .cards{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            }

            @media screen and (max-width: 446px){

                .card-employer{
                    background-color: var(--light-color);
                    box-shadow: 0px 0px 8px 4px #b5b3b3;
width: 100%;
                    max-height:780px;
                   }

                }
                @media screen and (max-width: 436px){

                    .card-employer{
                        background-color: var(--light-color);
                        box-shadow: 0px 0px 8px 4px #b5b3b3;
                        width: 100%;
                        max-height:780px;
                       }
    
                    }

        @media screen and (max-width: 396px){
            .map-states{
                width: 300px;
                height: 160px;
            }

            .states-left{
                margin-left: 0%;
            }

            .st{
                font-size: 14px;
            }
            .card-employer{
                background-color: var(--light-color);
                box-shadow: 0px 0px 8px 4px #b5b3b3;
                width: 320px;
                max-height:780px;
               }
               .img-page{
                width: 100%;
                height: 280px;
               }
               .btn-job{
                width: 100%;
               }
            }

            @media screen and (max-width: 376px){
                .map-states{
                    width: 300px;
                    height: 160px;
                }
    
                .states-left{
                    margin-left: 0%;
                }
    
                .st{
                    font-size: 12px;
                }

                .card-employer{
                    background-color: var(--light-color);
                    box-shadow: 0px 0px 8px 4px #b5b3b3;
                    width: 300px;
                    max-height:780px;
                   }
                   .img-page{
                    width: 100%;
                    height: 280px;
                   }
                   .btn-job{
                    width: 100%;
                   }
                }

                @media screen and (max-width: 336px){

                    .st{
                        font-size: 12px;
                        padding-right: 2px;
                        padding-left: 2px;
                    }
                    
                    .pool{
                        font-size: 30px;
                        margin-bottom: -1%;
                    }
                    .card-employer{
                        background-color: var(--light-color);
                        box-shadow: 0px 0px 8px 4px #b5b3b3;
                        width: 280px;
                        max-height:780px;
                       }
                       .img-page{
                        width: 100%;
                        height: 240px;
                       }
                       .btn-job{
                        width: 100%;
                       }
                    }

                    @media screen and (max-width: 326px){
                        .states-right{
                            margin-left: 0;
                        }
                        .st{
                            font-size: 12px;
                        }
                        .st{
                            font-size: 12px;
                            padding-right: 2px;
                            padding-left: 2px;
                        }

                        .choose-state{
                            font-size: 22px;
                        }

                        .pool{
                            font-size: 24px;
                            margin-bottom: -1%;
                        }

                        .text-pool{
                            font-size: 16px;

                            letter-spacing:normal;
                        }

                        .card-employer{
                            background-color: var(--light-color);
                            box-shadow: 0px 0px 8px 4px #b5b3b3;
                            width: 280px;
                            max-height:780px;
                           }
                           .img-page{
                            width: 100%;
                            height: 280px;
                           }
                           .btn-job{
                            width: 100%;
                           }
                        }
                        @media screen and (max-width: 306px){
                            .card-employer{
                                 background-color: var(--light-color);
                                 box-shadow: 0px 0px 8px 4px #b5b3b3;
                                 width: 280px;
                                 max-height:780px;
                                }
                                .img-page{
                                 width: 100%;
                                 height: 280px;
                                }
                                .btn-job{
                                 width: 100%;
                                }
                                                            
                             }
                        
                        @media screen and (max-width: 296px){
                            .card-employer{
                                 background-color: var(--light-color);
                                 box-shadow: 0px 0px 8px 4px #b5b3b3;
                                 width: 280px;
                                 max-height:780px;
                                }
                                .img-page{
                                 width: 100%;
                                 height: 280px;
                                }
                                .btn-job{
                                 width: 100%;
                                }
                                                            
                             }


                        @media screen and (max-width: 286px){
                       .card-employer{
                            background-color: var(--light-color);
                            box-shadow: 0px 0px 8px 4px #b5b3b3;
                            width: 280px;
                            max-height:780px;
                           }
                           .img-page{
                            width: 100%;
                            height: 280px;
                           }
                           .btn-job{
                            width: 100%;
                           }
                                                       
                        }


                        @media screen and (max-width: 286px){
                            .map-states{
                                width: 280px;
                                height: 160px;
                            }
                            .states-right{
                                margin-left: 0;
                            }
                            .card-employer{
                                background-color: var(--light-color);
                                box-shadow: 0px 0px 8px 4px #b5b3b3;
                                width: 280px;
                                max-height:780px;
                               }
                               .img-page{
                                width: 100%;
                                height: 230px;
                               }
                               .btn-job{
                                width: 100%;
                               }
.st{
    font-size: 12px;
}
.text-job-ny{
    max-width: 300px;
    padding: 40px;
}

                                                }