
  
  /* @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%);
     }
  } */
  
  .running-text {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: #ffffff 2px solid;
    border-top: #ffffff 2px solid;
    background-color: #244B7A;
  }
  
  /* .running-text span {
    display: inline-block;
    animation: marquee 2s linear infinite;
  } */

  .news{
          font-family: 'Merriweather', serif;
            font-size: 20px;
            font-weight: 400;
            word-spacing: 10px;
            color: var(--light-color);
            padding: 10px;

  }

  .running-text-right {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: #ffffff 2px solid;
    border-top: #ffffff 2px solid;
    background-color: #244B7A;
  }
  
  /* .running-text-right span {
    display: inline-block;
    animation: run 30s linear infinite;
  }

   
  @keyframes run {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%);
     }
  } */