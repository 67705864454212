body,
html {
	margin: 0;
}

* {
	box-sizing: border-box;
   }
:root {
	--main-color: #244B7A;
	--color: #CF3549;
	--light-color: #ffffff;
	--dark-color: #032144;
	--deep-dark: #011226;
}

.logoForm{
margin-bottom: 20px;
text-align: center;
}

.container{
  display: flex;
justify-content: center;
}
.app-wrapper-main-log{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100% ;
}
.app-wrapper-loginn{
  background-color: #ffffff;
  max-height: 1000px;
  padding: 50px;
  border: 3px #244B7A solid;
  align-self: center;
}

.title{
  color: var(--dark-color);
  text-align: center;
  font-size: 32px;
  margin: 60px 0px 40px 0px;
}

 .email-log, .password-log{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 280px;
  margin: 14px;
  color: var(--deep-dark);
  font-size: 19px;

}
.label-log{
width: 100%;
  font-family: 'Merriweather', serif;
	font-size: 18px;
	font-weight: 600;
	word-spacing: 10px;
}
.password-log{
  margin-bottom: 60px;

}
.start{
  margin-right: auto;
}
.input-log-sum{
  height: 32px;
  font-size: 20px;
  outline: none;
width: 100%;
  height: 36px;
  border-radius: 6px;
  border: 1px solid #d8d8d8;
}
.form-wrapper{
  width: 100%;
}
.error{
  color: var(--color);
}
.submit{
  display: flex;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  border-radius: 10px;
  border: none;
	width: 240px;
	height: 50px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 60px;
  background-color: var(--main-color);
  margin: auto;
  outline: none;
  cursor: pointer;
  transition: all 0.1s;
  text-align: center;

}

button:active{
  transform: translate(2px);
}

.fom-succes{
  display: flex;
  justify-content: center;
  margin-top: 250px;
  min-width: 370px;
  color: #0619e6;
}

.info-log{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--main-color);
  height: 100vh;
  width: 100vh ;
}

.info-dis{
  align-self: center;

}

.title2{
  color: var(--light-color);
  font-size: 42px;
}
.mintext{
  color: var(--light-color);
  font-size: 24px;
  font-weight: 300;
  padding: 10px;


}
ul{
  margin-top: 60px;
}
@media all and (max-width:1024px){
  .container{
    display: flex;
    flex-direction: column;
  }
  .info-log{
    display: none;
  }
}
@media all and (max-width:912px){
  .container{
    display: flex;
    flex-direction: column;
  }
  .info-log{
    display: none;
  }
}

@media all and (max-width:406px){
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .app-wrapper-loginn{
width: 100%;
padding: 10px;
  }
}

@media all and (max-width:366px){
  .container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .app-wrapper-loginn{
width: 100%;
padding: 10px;
  }
}