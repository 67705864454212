@font-face{
	font-family: 'Merriweather';
	src: url(../Anketa/Merriweather-Light.ttf);
}


.header-level{
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 0 20px;
  background-color: transparent;
  box-shadow: 0 0 4px 4px rgba(21, 1, 202, 0.25);
  position: relative;

}

.item-buttonss {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  color: var(--dark-color);
  padding: 16px 44px;
  word-wrap: break-word;
  cursor: pointer;
  transition: transform .5s;
  text-align: center;
  margin-left: 5%;

}
.item-buttonss-login {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  color: var(--dark-color);
  padding: 16px 44px;
  word-wrap: break-word;
  cursor: pointer;
  transition: transform .5s;
  text-align: center;
  margin-right: 2%;

}
.header-logo{
  font-size: 20px;
  line-height:1 ;
  font-weight: 700px;
  color: beige;
  margin-top: 40px;
}

.header-nav{
  margin-left: auto;
}

.header-nav-list{
  display: flex;
  list-style-type: none;
}

.header-nav-items{
  line-height: 1;
  font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 24px;
    text-decoration: none;
    color: var(--dark-color);
    padding: 10px 20px;
  cursor: pointer;
    transition: transform .5s;
    text-align: center;

}

.aboutt{
  max-width: 550px;
}

.header-nav-items:hover {
    color: var(--color);
    -ms-transform: scale3d(1.1, 1.1, 1.1);
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
    transition: 0.5s ease-in-out;
}

.header-nav-item:nth-child(n + 2){
  margin-left: 18px;
}

.header-menu-button{
  display: none;
}

.about-us-main{
  width: 150px;
}
.login-l, .sign-l {
margin-bottom: 26px;

}

.wel-email-main{

	font-family: 'Roboto', sans-serif;
	  font-weight: bolder;
	  font-size: 24px;
	  text-decoration: none;
	  color: var(--light-color);
	  padding: 10px 14px;
text-align: center;
	cursor: pointer;
	  transition: transform .5s;
  }

.item-button-l {
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 20px;
    text-decoration: none;
    color: var(--dark-color);
    padding: 18px 30px;
}

.mar-left{
  margin-left: -1%;
}
.mar-right{
  margin-left: 50%;
}
.login-l {
    background-color: var(--main-color);
    color: var(--light-color);
padding-right: 40px;
text-align: center;

  
}

.login-l:hover {
    background-color: var(--color);
    color: var(--light-color);
    border: none;
}

.sign-l {
    background-color: var(--color);
    color: var(--light-color);
}

.employer{
  color: var(--color);
margin-right: 10%;
text-align: center;

}


.employer:hover{
  color: var(--dark-color);
}

.sign-l:hover {
    background-color: var(--main-color);
    color: var(--dark-color);
    border: none;
    color: var(--light-color);
}

.welcome {
  background-color: transparent;
  color: var(--main-color);
font-size: 20px;
  -webkit-animation: pulsing 2s infinite;
  animation: pulsing 2s infinite;
word-wrap: break-word;
text-align: start;

}

.first-text, .second-text, .third-text{
	word-wrap: break-word;
}


/*first block*/
.levels{
  background-image: url(../../../public/images/photo.jpg);
  background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
  width: 100%;
}
.level-first-block{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top:2%;


}

.appliName-level{
  flex-direction: column;
	justify-content: center;
	border-radius: 8px;
max-width: 500px;
  background-size: cover;
	box-shadow: 0px 0px 8px 4px #b5b3b3;
  margin-bottom: 10%;
  max-height: 2800px;
}


.hr-level{
background-color: var(--main-color);
width: 100%;
height: 10px;
}


 .level-list{
  margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 36px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;

} 

.text-discription-level{
margin-top: 5%;
font-family: 'Merriweather', serif;
margin-left: -4%;
}
.description-level{
  align-items: center;
  line-height: 40px;
}

.level:hover {
	transform: scale(1.1);
}

.price-del-level{
  margin-top: 10px;
	margin-left: 40%;
	font-size: 28px;
	font-weight: 500;
	color: var(--deep-dark);
	text-transform: uppercase;


}

.price-level{
  margin-top: 10px;
	text-decoration: none;
	font-size: 36px;
	font-weight: bolder;
	color: var(--color);
	text-transform: uppercase;

}

.text-discription-level{
  min-height: 400px;
  width: 100%;
  justify-content: center;
}

.description-level{
	display: flex;
	color: var(--deep-dark);
	font-size: 19px;
	font-weight: 600;
margin-left: 2%;	
font-family: 'Merriweather', serif;
padding: 8px;
line-height: 27px;
}
.description-level-two{
	display: flex;
	color: var(--deep-dark);
	font-size: 19px;
	font-weight: 600;
font-family: 'Merriweather', serif;
padding: 8px;
line-height: 27px;
}


.video-level{

}
.youtube-leveltwo{
width: 800px;
  height: 726px;
border-radius: 10px;
  align-self: center;
margin-bottom: 10%;
  box-shadow: 0 0 4px 4px rgba(21, 1, 202, 0.25);

}
.h2-level{
  margin-top: 50px;
	margin-bottom: 10px;
	text-align: center;
	font-size: 50px;
	font-weight: bolder;
	color: var(--dark-color);
	text-transform: uppercase;
  margin-top: 6%;
  margin-bottom: 4%;
}
.text-leveltwo{
  display: flex;
  justify-content: center;
  flex-direction: column;

  align-items: center;
}
.text-levelTwo{
  font-size: 20px;
  font-weight: 600;
  max-width:1250px;
  max-height: 5000px;
  text-align: start;
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  line-height: 32px;
  padding: 20px;
  margin-top: -3%;
}
.LinksER{
display: flex;
flex-direction: column;
justify-content: center;
}

.Text-Link{
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  margin-top: 5%;

  text-align: center;
}
.links-chatt{
  display: flex;
  justify-content: center;


}
.en, .ru{
	border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 220px;
	height: 44px;
	color: var(--light-color);
	font-weight: 400;
	text-transform: uppercase;
	font-size: 12px;
	cursor: pointer;
  text-align: center;
  margin: 8px;
}
.en, .ru{
  font-weight: 600;
cursor: pointer;
padding: 10px;
}
.ru:hover {
	background: var(--dark-color);
	color: var(--light-color);
}
.en:hover {
	background: var(--dark-color);
	color: var(--light-color);
}
.text-levelonee{
  display: flex;
  justify-content: center;
  flex-direction: column;
margin-top: -1%;
  align-items: center;
}
.button-levels {
	border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 240px;
	height: 50px;
	color: var(--light-color);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
margin-left: 20%;

}

.button-levels:hover {
	background: var(--dark-color);
	color: var(--light-color);
}

footer {
  background-color: var(--main-color);
  padding: 80px;
  color: var(--light-color);
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-column-f {
  flex-basis: 33.33%;
  padding: 0 100px;
}

.foot {
  margin-bottom: 10px;
margin-left: 10px;
color: var(--light-color);
}

.ul-lev {
  list-style: none;
  padding: 0;
}

.link-level{
margin-left: auto;
}

.ul-lev{
  margin-top: 18px;
}

.marg{
  margin-top: 1%;
  word-break: break-all;
}
.eb3-un{
  max-width: 124px;
}



/*level 1 */

.videoLev{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;


}
.text-level-important{
  font-size: 20px;
  font-weight: 600;
  max-width:1400px;
  max-height: 20000px;
  text-align: justify;
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  line-height: 28px;
  word-spacing: 1px;
}

.important{
  background: none;
  text-align: center;
  font-weight: 600;
    color: var(--color);
    font-size: 26px;
}

.arrow-imp{
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% { 
    transform: scale(0.8);
  }

  50% { transform: scale(1.1);
  }

  100% {
    transform: scale(0.8);
  } 
}

.video-level-one{
  margin-top: 5%;
}

.border-text{
  border: #CF3549 3px solid;
  padding: 10px;
  margin-top: 2%;
}
.youtube-levelone{
width: 450px;
height: 264px;
padding: 10px;
}

.levelone-text{
  background-color: var(--light-color);
  box-shadow: 0px 0px 8px 4px #b5b3b3;
  max-height: 20000px;
max-width: 450px;
margin: 16px;
}
.levelone-text-red{
  background-color: var(--light-color);
  box-shadow: 0px 0px 8px 4px #CF3549;
  max-height: 20000px;
max-width: 450px;
margin: 16px;
}
.levelone-text-bottom{
  background-color: var(--light-color);
  box-shadow: 0px 0px 8px 4px #b5b3b3;
  max-height: 5000px;
max-width: 450px;
margin: 16px;
margin-bottom: 20%;
}
.text-levelOneMain{
  font-size: 18px;
  font-weight: bolder;
  max-height: 5000px;
  text-align: start;
  color: var(--deep-dark);

  font-family: 'Merriweather', serif;
  padding: 6px;
}
.text-levelmini{
  font-size: 14px;
  font-weight: 500;
  max-height: 5000px;
  text-align: start;
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  margin-top: -4%;
  padding: 6px;
}

.hr-levelone, .hr-levelone-top{
  height: 4px;
  background-color: var(--main-color);
}
.hr-levelone-white{
  height: 4px;
  background-color: var(--light-color);
  border: none;
}

.hr-levelone-top{
margin-top: -5%;
}
.text-levelOne{
  font-size: 16px;
  font-weight: 600;
  max-height: 5000px;
  text-align: start;
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  word-spacing: 3px;
  line-height: 22px;
  padding: 6px;
}

.text-levelOneRead{
  font-size: 16px;
  font-weight: 600;
  max-height: 5000px;
  text-align: start;
  color: var(--deep-dark);
  font-family: 'Merriweather', serif;
  word-spacing: 3px;
  line-height: 22px;
  padding: 8px;
  margin-top: -2%;
  
}

.table-AB{
  display: flex;
  justify-content: center;
}

.AB-column{
  display: flex;
  flex-direction: column;
}

/* level 2 */

.button-level2{
  display: flex;
  justify-content: center;
  align-items: center;
  /* max-width: 650px; */
  /* margin-left: 10%; */
  padding: 10px;
   margin-top: -10%; 
}

.button-level-two {
	border: none;
	background: var(--main-color);
	border-radius: 10px;
	width: 240px;
	height: 50px;
	color: var(--light-color);
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
margin-bottom: 10%;
margin-top: 8%;
margin-left: 1%;

}

.button-level-two:hover {
	background: var(--dark-color);
	color: var(--light-color);
}

.mt-level {
	margin-top: 18px;
	font-size: 16px;
	font-weight: 600;
	font-family: 'Merriweather', serif;
	}

  .label-level {
    font-family: 'Merriweather', serif;
    font-size: 17px;
    padding: 10px;
    margin: 10px;
    font-weight: 400;
    word-spacing: 8px;
  }
  .pool-btn{
    display: flex;
    justify-content: center;
  }

  .button-pool{
display: flex;
justify-content: space-evenly;
margin-bottom: 5%;
  }
  .btn-pool{
    border: none;
    background: var(--main-color);
    border-radius: 10px;
    width: 200px;
    height: 50px;
    color: var(--light-color);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
cursor: pointer;
text-align: center;

margin-bottom: 3%;
  }

  .btn-pool:hover{
    background: var(--dark-color);
    color: var(--light-color);
  }

  .we-creat{
    font-family: 'Playfair Display', serif;
    color: var(--light-color);
    font-weight: lighter;
  margin-bottom: 1%;
  }




/*AOS and CP*/

.tabels-time{
  display: flex;
  justify-content: space-evenly;

}

.AOS-time{
  display: flex;
  flex-direction: column;
  
}
.AOS{
  width: 100%;
  padding: 14px;
}
.CP{
  width: 100%;
  padding: 14px;
  margin-top: 1.4%;
}
.text-AOS{
  font-size: 18px;
	font-weight: 600;
	font-family: 'Merriweather', serif;
  text-align: center;
  margin-bottom: -2%;
}
.text-bulletin{
  font-size: 20px;
	font-weight: 600;
	font-family: 'Merriweather', serif;
  text-align: center;
  padding-left: 40px;
  padding-right: 40px;
  color: var(--deep-dark);

}
.bulletin{
margin-top: 4%;
}
.btn-bull{
  text-transform: uppercase;
	border-radius: 10px;
	border: none;
	width: 200px;
	height: 40px;
	font-size: 14px;
	color: #ffffff;
	padding: 13px 40px;
	background-color: var(--main-color);
	margin: auto;
	outline: none;
	cursor: pointer;
	transition: all 0.1s;
  text-align: center;
}
.btn-bull:hover {
	background: var(--dark-color);
	color: var(--light-color);
}
.btn-bulletin{
  display: flex;
  justify-content: center;
  margin-bottom: 10%;
}
.hr-bull{
  width: 100%;
  height: 2px;
  background-color: rgb(154, 154, 155);
}
.hr-bull-bot{
  width: 100%;
  height: 2px;
  background-color: rgb(154, 154, 155);
  margin-bottom: 5%;
  margin-top: 2%;
}
  @media (max-width:1920px){

    .footer-column {
      padding: 0 100px;
    }
  }
   @media (max-width:1606px) {

    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    } 



    @media (max-width:1385px) {

    .header-nav-items{
      line-height: 1;
      font-family: 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 20px;
        text-decoration: none;
        padding: 10px 14px;
      align-items: center;
      cursor: pointer;
        transition: transform .5s;
    }

  }
  @media (max-width:1265px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-items{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 10px;
    }
    }
  @media (max-width:1245px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-items{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 10px;
    }
    }
  @media (max-width:1225px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 10px;
    }
    }
  @media (max-width:1205px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 10px;
    }
    }
  @media (max-width:1195px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 10px;
    }
    }
  @media (max-width:1156px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 20px;
    }
    }
  @media (max-width:1085px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 18px;
    }
    }
  @media (max-width:1024px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 18px;
    }
    }
  @media (max-width:1006px) {
    .header-nav{
      position: fixed;
      top: 0;
      left: -225px;
      display: flex;
      flex-direction: column;
      width: 220px;
      height: 100%;
      background-color: var(--light-color);
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
      transition: transform 0.3s linear;
      
    }
  
    .header-nav.active{
      transform: translateX(100%);
    }
  
    .header-nav-list{
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 10px;
  
    }
  
    .header-nav-item{
      text-align: center;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 16px 26px;
    }
  
    .header-nav-item:nth-child(n + 2){
      margin-left: 0;
    }
  
    .header-menu-button{
      display: block;
      margin-left: auto;
      background-color: transparent;
      padding: 0;
      border: 0;
    }
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    footer {
      padding: 18px;
    }
    }


@media (max-width:924px) {

  .header-nav{
    position: fixed;
    top: 0;
    left: -225px;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    background-color: var(--light-color);
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s linear;
    
  }

  .header-nav.active{
    transform: translateX(100%);
  }

  .header-nav-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

  }

  .header-nav-item{
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    padding: 16px 26px;
  }

  .header-nav-item:nth-child(n + 2){
    margin-left: 0;
  }

  .header-menu-button{
    display: block;
    margin-left: auto;
    background-color: transparent;
    padding: 0;
    border: 0;
  }
  .level-first-block{
    display: flex;
    flex-direction: column;
  }
  .tabels-time{
    display: flex;
    flex-direction: column;
  }
}
@media (max-width:846px){
  .text-level-important{
    text-align: justify;
  }
}

@media all and (max-width:800px) {


.appliName-level{
  width: 100%;
}

.youtube-level1{
  width: 100%;
}

}
@media (max-width: 798px){
  .youtube-leveltwo{
    width: 100%;
    height: 350px;
  }
  .youtube-levelone{
    width: 100%;
    height: 240px;
    padding: 10px;
    
    }
}
@media (max-width: 786px){
  .youtube-leveltwo{
    width: 100%;
  }

}
@media (max-width: 740px) {
  .footer-column-f {
    flex-basis: 20%;
    padding: 50px 40px;
  }
.foot{
  margin-left: 10px;
  font-size: 16px;
  color: black;
}
  .ul-lev{
    margin-top: 18px;
  }
}
@media (max-width: 720px) {
.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
}
@media (max-width: 620px) {
footer {
  padding: 55px;
}
}

@media (max-width: 616px) {
  footer {
    padding: 55px;
  }

  .text-level-important{
    font-size: 20px;
    font-weight: 600;
    max-width:1400px;
    max-height: 20000px;
    text-align: start;
    color: var(--deep-dark);
    font-family: 'Merriweather', serif;
    line-height: 28px;
    word-spacing: 1px;
  }
}

@media (max-width: 590px) {
  footer {
    padding: 45px;
  }
}



@media (max-width:579px) {
  .header-nav{
    position: fixed;
    top: 0;
    left: -225px;
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 100%;
    background-color: var(--light-color);
    box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.25);
    transition: transform 0.3s linear;
    
  }

  .header-nav.active{
    transform: translateX(100%);
  }

  .header-nav-list{
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

  }

  .header-nav-item{
    text-align: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    padding: 16px 26px;
  }

  .header-nav-item:nth-child(n + 2){
    margin-left: 0;
  }

  .header-menu-button{
    display: block;
    margin-left: auto;
    background-color: transparent;
    padding: 0;
    border: 0;
  }

  .youtube-level1{
margin-top: 60px;
width:350px ;
height: 215px;
  }
  


.level-first-block{
  display: flex;
  flex-direction: column;
}


}
@media (max-width: 556px) {
  footer {
    padding: 80px;
  }
  .level-list{
    font-size: 28px;
  }
}
@media (max-width: 506px) {
.text-AOS{
  font-size: 16px;
}
}
@media (max-width: 526px) {
  .table-AB{
    display: flex;
    justify-content: center;
  }
}
@media all and (max-width:486px) {

}
@media (max-width: 462px) {
  .links-chatt {
display: flex;
flex-direction: column;
margin-left: auto;
margin-right: auto;
  }
}

@media (max-width: 446px) {
  .text-AOS{
    font-size: 15px;
    margin-bottom: -8%;
  }
  .table-AB{
    display: flex;
    flex-direction: column;
  }
  .text-bulletin{
    font-size: 18px;
  }
  }

@media (max-width: 436px) {
  footer {
    padding: 30px;
  }
}
@media (max-width: 426px) {
  .button-pool{
    display: flex;
    flex-direction: column;
align-items: center;
  }
}

@media (max-width: 406px) {
.youtube-levelone{
  height: 220px;
}
}
@media (max-width: 386px){
  .h2-level{
font-size: 30px;
  }
  .youtube-levelone{
    height: 210px;
  }
}

@media (max-width: 376px) {
.btn-read-link{
  width: 120px;
}
  }
@media (max-width: 366px) {
  .youtube-levelone{
    height: 200px;
  }
  }
  @media (max-width: 356px) {
    .youtube-levelone{
      height: 190px;
    }
    .btn-read-link{
      width: 110px;
    }
    }
    @media (max-width: 346px) {
      .youtube-levelone{
        height: 190px;
      }
      .h2-level{
        font-size: 28px;
      }
      .btn-read-link{
        width: 100px;
      }
      }

      @media (max-width: 336px) {
        .youtube-levelone{
          height: 180px;
        }
        .btn-read-link{
          width: 80px;
          height: 40px;
        }
        }
@media (max-width: 326px) {
  footer {
    padding: 10px;
  }
  .youtube-levelone{
    height: 174px;
  }
  .btn-read-link{
    width: 80px;
    height: 40px;
  }
}
@media (max-width: 316px) {
  .btn-read-link{
    width: 74px;
    height: 34px;
    font-size: 14px;
  }
}
@media (max-width: 306px) {
  footer {
    padding: 4px;
  }
  .youtube-levelone{
    height: 168px;
  }
  .text-AOS{
    font-size: 14px;
    margin-bottom: -12%;
  }
  .text-bulletin{
    font-size: 20px;
  }
  .btn-read-link{
    width: 70px;
    height: 32px;
    font-size: 14px;
  }
  .link-table{
    margin-left: -1%;
  }
  .text-bulletin{
    font-size: 15px;
  }
  .btn-bull{
    font-size: 11px;
    width: 180px;
  }
}

@media (max-width: 296px) {
  .btn-read-link{
    width: 68px;
    height: 30px;
    font-size: 14px;
  }
  .link-table{
    margin-left: -1.8%;
    margin-bottom: 8%;
  }}
@media (max-width: 286px) {
  footer {
    padding: 2px;
  }
  .foot{
    color: var(--light-color);
  }
  .text-AOS{
    font-size: 13px;
    margin-bottom: -14%;
  }
  .btn-read-link{
    width: 66px;
    height: 28px;
    font-size: 12px;
  }
  .link-table{
    margin-left: -6%;
    margin-bottom: 10%;
  }
}


@media (max-width:280px) {
.youtube-level1{
  width:180px ;
  height: 215px;
}

}